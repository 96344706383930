import React, { useEffect, useState } from "react";
import NewLogin from "./NewLogin";
import LoginKyc from "./LoginKyc";
import { useCleverTap } from '../CleverTapContext';

const MainNewLogin = () => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [is_onboard, setIs_onboard] = useState(false);
  const [heading, setHeading] = useState("Enter Your Mobile Number");
  const trackEvent = useCleverTap();

  const handleKyc = () => {
    setSelectedOption(2);
  };

  const isOnboard = (value) => {
    setIs_onboard(value);
  };

  useEffect(() => {
    trackEvent("page_loaded", "saathi_web_landing");
    document.body.classList.add("login-page");
    return () => {
      document.body.classList.remove("login-page");
    };
  }, []);

  useEffect(() => {
    if (is_onboard) {
      setHeading("Create Your Ambak Saathi Account");
    } else {
      setHeading("Login To Ambak Saathi");
    }
  }, [is_onboard]);

  return (
    <>
      <div>
        <div className="logincontainer">
          <div className="left-side">
            <div>
              <div className="blueBg">
                {selectedOption === 1 && (
                  <>
                    <div className="leftheadinglogin">
                      <p className="loginpara">
                        Welcome To
                        <span
                          style={{
                            color: "#6E55B8",
                            fontWeight: 600,
                            marginLeft: 5,
                          }}
                        >
                          Ambak
                        </span>
                      </p>
                      <h1 className="lgheading">
                        <span
                          style={{
                            fontSize: 24,
                            fontWeight: 400,
                            marginBottom: "10px",
                          }}
                        >
                          Your Partner for
                        </span>
                        <br />
                        Hassle-Free Home Loans
                      </h1>
                    </div>
                    <div className="newloginimg">
                      <img
                        src={require("../../saathi-web/images/newloginingone.svg")}
                        alt=""
                      />
                    </div>
                  </>
                )}

                {selectedOption === 2 && (
                  <>
                    <div className="leftheadinglogin">
                      <p className="loginpara">
                        Welcome To
                        <span
                          style={{
                            color: "#6E55B8",
                            fontWeight: 600,
                            marginLeft: 5,
                          }}
                        >
                          Ambak
                        </span>
                      </p>
                      <h1 className="lgheading">
                        Complete
                        <br />
                        Your KYC Verification
                      </h1>
                    </div>
                    <div className="newloginimgkyc">
                      <img
                        src={require("../../saathi-web/images/newloginingtwo.svg")}
                        alt=""
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="right-side">
            <div className="shadow-box">
              <div className="top-content">
                {selectedOption !== 2 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://static.ambak.com/saathi/ambak_logo.svg"
                        alt="logo"
                        width={186}
                        height={60}
                      />
                    </div>
                  </>
                )}

                <div className="logindetialsnew">
                  <div className="subheadinglogin">Let’s Get Started!</div>
                  <div className="mainheadingloginnew">
                    {heading}
                  </div>
                </div>
              </div>

              <div className="middle-content">
                {selectedOption === 1 && (
                  <NewLogin setKycForm={handleKyc} isOnboard1={isOnboard} />
                )}

                {selectedOption === 2 && <LoginKyc />}
              </div>

              <div className="bottom-content">
                {selectedOption !== 2 && (
                  <div className="policylink">
                    By continuing, I agree to Ambak’s{" "}
                    <a href="https://ambak.com/terms-of-use">Terms of Use </a> &{" "}
                    <a href="https://ambak.com/privacy-policy">Privacy Policy </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNewLogin;