import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { RoleAccess } from "../../../config/constant";


const LoanOffersCalculator = (props) => {

  const history = useHistory();

  const CibilCheck = () =>{
    history.push("/my-tool-cibil");
  }

  const PayoutCheck = () =>{
    history.push("/payout-calculator");
  }

  const EmiCheck = () =>{
    history.push("/emi-calculator");
  }
  const APFsearch = () =>{
    history.push("/apf-search");
  }


  const apf_search_engine_tool = RoleAccess("apf_search_engine_tool");
  const emi_calculator_tool = RoleAccess("emi_calculator_tool");
  const payout_calculator_tool = RoleAccess("payout_calculator_tool");
  const doc_list_generator_tool = RoleAccess("doc_list_generator_tool");
  
 
  return (
    <div className="offercalculator">
      <div className="offerloanflex">
        <div className="offercalcard">
          <div>
            <img
              src="https://static.ambak.com/saathi/1726745864804.svg"
              alt="loan offer"
              width={110}
              height={80}
            />
          </div>
          <div>
            <div className="loanoffercibil">
              Get your Customer Free CIBIL Score & Report instantly.
            </div>
            <button className="loanofferbtn" onClick={CibilCheck}>Check Now</button>
          </div>
        </div>
        <div className="offercalcard">
          <div style={{width:"15%"}}>
            <img
              src="https://static.ambak.com/saathi/1726745953722.svg"
              alt="loan offer"
              width={80}
              height={80}
            />
          </div>
          <div>
            <div className="loanoffercibil">
            Check out best home  loan offers with Ambak’s free offer calculator
            </div>
            <button
              className="loanofferbtn"
              onClick={() => props.setCheckOfferLoan(true)}
            >
              Check Now
            </button>
          </div>
        </div>
      </div>

      <div className="offerloanflex">
        {apf_search_engine_tool === 1 ? <div className="offerlistcard">
          <img
            src="https://static.ambak.com/saathi/1723192167400.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">APF Search Engine</div>

          <button className="offercommonbtn" onClick={APFsearch}> Check Now</button>
        </div> : ""}

        {emi_calculator_tool === 1 ?  <div className="offerlistcard">
          <img
            src="https://static.ambak.com/saathi/1723193405707.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">EMI Calculator</div>

          <button className="offercommonbtn" onClick={EmiCheck}> Check Now</button>
        </div> : ""}

        {payout_calculator_tool === 1 ? <div className="offerlistcard">
          <img
            src="https://static.ambak.com/saathi/1723193491801.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">Payout Calculator</div>

          <button className="offercommonbtn" onClick={PayoutCheck}> Check Now</button>
        </div> : ""}

        {doc_list_generator_tool === 1 ? <div className="offerlistcard">
          <img
            src="https://static.ambak.com/saathi/1723193561304.png"
            alt="loan offer"
            width={80}
            height={80}
          />

          <div className="offerloanheading">Doc List Generator</div>

          <button className="offercommonbtn"> Check Now</button>
        </div> : ""}
      </div>
    </div>
  );
};

export default LoanOffersCalculator;
