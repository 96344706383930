import React, { useEffect, useState } from "react";
import { UPDATE_LEAD_STATUS } from "../queries/sangam/leads.gql";
import { executeGraphQLMutationFinex } from "../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import Modal from "../view/common/Modal";
import FormsyDatePicker from "../view/elements/FormsyDatePicker";
import Formsy from "formsy-react";

const LoanStatusByStatus = (leadDetail) => {
const [pending_pdd_status_array, setPendingPddStatusArray] = useState([]);
const [disbursementDetails,setDisbursementDetails] = useState([]);
const [showModal,setShowModal] = useState(false)
const [loginDetails, setLoginDetails] = useState({
  loginNumber: "",
  loginAmount: "",
});
const [sanctionDetails, setApprovedDetails] = useState({
  sanctionId: "",
  sanctionAmount: "",
  sanctionEmi: "",
});
const splitpayment = leadDetail?.leadDetail?.splitpayment;
const partner_id = leadDetail?.leadDetail?.partner_id;
const [updatedPddDate, setUpdatedPddDate] = useState("");

const [modalType, setModalType] = useState(""); 
const [transactionMode, setTransactionMode] = useState("");
const [transactionDate, setTransactionDate] = useState("");

console.log("leadDetail",leadDetail)

const ShowPDDModal = () => {
  setModalType("pdd");
  setShowModal(true);
};

const ShowTransactionModal = () => {
  setModalType("transaction");
  setShowModal(true);
};

const CloseUpdateModal = () => {
  setShowModal(false);
  setModalType(""); 
};

console.log("splitpayment",splitpayment)
  useEffect(() => {
    if (splitpayment && typeof splitpayment === "object") {
      const pending_pdd_status = Object.entries(splitpayment).map(([key, value]) => ({
        id: key,
        ...value, 
      }));
      setDisbursementDetails(pending_pdd_status[0])
      setPendingPddStatusArray(pending_pdd_status[0]?.pending_pdd_status || []);
      setLoginDetails({
        loginNumber: leadDetail?.leadDetail?.lead_details.login_number || "",
        loginAmount: leadDetail.leadDetail.lead_details.login_amount || "",
      });  
      setApprovedDetails({
        sanctionId: leadDetail?.leadDetail?.lead_details.approval_number || "",
        sanctionAmount: leadDetail?.leadDetail?.lead_details.approved_amount || "",
        sanctionEmi: leadDetail?.leadDetail?.lead_details.approved_emi || "",
      })  
    }
  },[leadDetail])

console.log("pending_pdd_status_array",pending_pdd_status_array)

  const transformed_status_array = pending_pdd_status_array?.map((item) => {
    let status = "Pending";
  
    if (item.name === "Transaction" || item.name === "PDD") {
      if (item.date) {
        status = item.is_verified ? item.date : "Pending";
      } else {
        status = "Update";
      }
    } 
    

    else if (item.name === "MIS") {
      if (item.date) {
        status = item.is_verified ? "Received" : "Pending";
      } else {
        status = "Pending";
      }




    } else if (item.name === "Pay-In") {
      if (item.date) {
        status = item.is_verified ? "Received" : "Pending";
      } else {
        status = "Pending";
      }
    }

    else if(item.name==="Payout"){
      if(item.payout_status==="Paid"){
        status=item.payout_date||"Paid"
      }else if(item.payout_status==="ETA"){
        status=`${item.payout_expected_date}`||"Pending"
      }else{
        status="Pending"
      }
    }
    return {
      ...item,
      status,
    };
  });

  const handlePddDateChange = (name, value) => {
    const date = new Date(name);
    setUpdatedPddDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);

  };

  const handleTransDateChange = (name, value) => {
    const date = new Date(name);
    setTransactionDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);

  };



  const ShowUpdateModal = () =>{
    setShowModal(true)
    document.body.classList.add("overflow-hidden");
  }
  
  const handleClick = () => {
    let mutation = UPDATE_LEAD_STATUS,
          variables = {
              api_called_by: 'saathi_web',
              partner_id: partner_id,
              UpdateLeadStatus: {
                  update_type: "pdd",
                  status_id: [4],
                  lead_id: leadDetail?.leadDetail?.id,
                  user_id: 38,
                pdd_form: {
                  id: disbursementDetails?.id,
                  pdd_date: updatedPddDate,
                }
              }
          };
          executeGraphQLMutationFinex(mutation, variables)
          .then((resp) => {
              let response = resp?.data?.update_lead_status || null;
              if (response) {
                  toast.success("Success")
              }
              else {
                  toast.error("Error");
              }
          })
          .catch((err) => {
              toast.error("Error")
          })
  };

  const handleTransClick = () => {
    let mutation = UPDATE_LEAD_STATUS,
    variables = {
        api_called_by: 'saathi_web',
        partner_id: partner_id,
        UpdateLeadStatus: {
            update_type: "pdd",
            status_id: [4],
            lead_id: leadDetail?.leadDetail?.id,
            user_id: 38,
          pdd_form: {
            id: disbursementDetails?.id,
            transaction_mode: transactionMode,
            transaction_date: transactionDate,
          }
        }
    };
    executeGraphQLMutationFinex(mutation, variables)
    .then((resp) => {
        let response = resp?.data?.update_lead_status || null;
        if (response) {
            toast.success("Success")
        }
        else {
            toast.error("Error");
        }
    })
    .catch((err) => {
        toast.error("Error")
    })

  }
  

  
  return (
    <>
      {(disbursementDetails?.disbursed_id&&disbursementDetails?.disbursed_amount && loginDetails?.loginNumber &&loginDetails?.loginAmount 
            &&sanctionDetails?.sanctionId && sanctionDetails?.sanctionAmount&&sanctionDetails?.sanctionEmi) ?(
              <div className="rightbottomcontainer" style={{ marginBottom: 20 }}>

            <div>
          <div className="folloupheading">Disbursed Details</div>

    <ul>
    {transformed_status_array?.map((item, index) => (
      <li key={index} className="disbursecommonnew">
        <div className="commonimgflex">
          <span>
            <img  src={
              (item.name === "Transaction" || item.name === "PDD") &&
              item.status === item.date
                ? "https://static.ambak.com/saathi/1733562776847.png":
                (item.name === "Pay-In"||item.name === "MIS" ) && item.status === "Received"?
               "https://static.ambak.com/saathi/1733562776847.png":
                (item.name === "Payout" ) && item.status === "Paid"?
                "https://static.ambak.com/saathi/1733562776847.png"
                :  (item.name === "Transaction" || item.name === "PDD") && item.status === "Pending"
                ? "https://static.ambak.com/saathi/1733562923039.png"
                :  (item.name === "Transaction" || item.name === "PDD") && item.status === "Update"
                ? "https://static.ambak.com/saathi/1733563093839.png"
                : "https://static.ambak.com/saathi/1733563093839.png"
            } 
            alt="icon"
            width={16}
            height={16}/>
          </span>
          {item.name==="MIS"?"Bank-MIS":item.name==="Pay-In"?"Collection":item.name}
        </div>
        <div
          className={
            (item.name === "Transaction"|| item.name === "PDD" || item.name === "Pay-In" || item.name === "MIS"
            ) &&
            (item.status === item.date || item.status === "Received")?"transaction": item.status === item.payout_expected_date ? "etaDate":item.status === "Pending" ? "disbursepending" : "loanstatuscolor"
          }
        >
          {item.status === item.payout_expected_date ? (
        <span>{`ETA- ${item.payout_expected_date}`}</span>
      ) : (item.status === "Update" &&item.name  === "PDD")? (
        <span onClick={ShowPDDModal} className="updatepdddate">Update</span>
      ) : ((item.status === "Update" &&item.name  === "Transaction"))?
      <span onClick={ShowTransactionModal} className="updatepdddate">Update</span>
      :(
        <span>{item.status}</span>
      )}      </div>
      </li>
    ))}
      </ul>
          <div >    
           
          </div>
         
          <div className="flexrightdiv">
            <div className="loanstatuscolor">Disbursed ID</div>
            <div className="loanstatuscolor">#{disbursementDetails?.disbursed_id}</div>
          </div>
          <div className="flexrightdiv">
            <div>Disbursed Amount</div>
            <div className="loanstatuscolor">₹ {disbursementDetails?.disbursed_amount}</div>
          </div>

          <div className="flexrightdiv">
            <div className="loanstatuscolor">Logged In ID</div>
            <div className="loanstatuscolor">#{loginDetails?.loginNumber}</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ {loginDetails?.loginAmount}</div>
              </li>
            </ul>
          </div>

          <div className="flexrightdiv">
            <div className="loanstatuscolor">Sanction ID</div>
            <div className="loanstatuscolor">#{sanctionDetails?.sanctionId}</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ {sanctionDetails?.sanctionAmount}</div>
              </li>
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>EMI
                </div>
                <div className="loanstatuscolor">₹ {sanctionDetails?.sanctionEmi}</div>
              </li>
            </ul>
          </div>
        </div>
        </div>

  ):(
    sanctionDetails?.sanctionId && sanctionDetails?.sanctionAmount&& sanctionDetails?.sanctionEmi ? (

      <div className="rightbottomcontainer" style={{ marginBottom: 20 }}>
        <div>
          
          <div className="folloupheading">Sanctioned Details</div>
      <div>
        <div className="flexrightdiv">
          <div className="loanstatuscolor">Logged In ID</div>
          <div className="loanstatuscolor">#{loginDetails?.loginNumber}</div>
        </div>
        <div>
          <ul className="loanstatuslist">
            <li className="flexrightdiv">
              <div className="otherinfocolor">
                <span>•</span>Amount
              </div>
              <div className="loanstatuscolor">₹ {loginDetails?.loginAmount}</div>
            </li>
          </ul>
        </div>

        <div className="flexrightdiv">
            <div className="loanstatuscolor">Sanction ID</div>
            <div className="loanstatuscolor">#{sanctionDetails?.sanctionId}</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ {sanctionDetails?.sanctionAmount}</div>
              </li>
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>EMI
                </div>
                <div className="loanstatuscolor">₹ {sanctionDetails?.sanctionEmi}</div>
              </li>
            </ul>
          </div>
      </div>
    </div>
        </div>
    ) : (
      loginDetails?.loginNumber && loginDetails?.loginAmount ? (
  
        <div className="rightbottomcontainer" style={{ marginBottom: 20 }}>
          <div>
            
            <div className="folloupheading">Logged In Details</div>
        <div>
          <div className="flexrightdiv">
            <div className="loanstatuscolor">Logged In ID</div>
            <div className="loanstatuscolor">#{loginDetails?.loginNumber}</div>
          </div>
          <div>
            <ul className="loanstatuslist">
              <li className="flexrightdiv">
                <div className="otherinfocolor">
                  <span>•</span>Amount
                </div>
                <div className="loanstatuscolor">₹ {loginDetails?.loginAmount}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
          </div>
      ) :null
  ))}
  
        
      <Modal isOpen={showModal} togglePopup={CloseUpdateModal} width={400}>
        <div>
          {modalType === "pdd" ? (
            <>
              <h3 className="modal-heading">Update PDD Date</h3>
              <Formsy>
                <fieldset
                  className="form-filed col-md-12"
                  style={{ marginBottom: 50 }}
                >
                  <FormsyDatePicker
                    name="pdd_date"
                    placeholder={"Date"}
                    value={updatedPddDate}
                    onDateSelect={(date) => handlePddDateChange(date)}
                    required={true}
                  />
                </fieldset>
                <button onClick={handleClick} className="updatepddbtn">
                  Save
                </button>
              </Formsy>
            </>
          ) : modalType === "transaction" ? (
            <>
            <h3 className="modal-heading">Update Transaction Mode</h3>
            <Formsy>
              <fieldset className="form-filed col-md-12" style={{ marginBottom: 10 }}>
                <label>Transaction Mode</label>
                <select
                  className="form-control"
                  value={transactionMode}
                  onChange={(e) => setTransactionMode(e.target.value)}
                >
                  <option value="">Select Transaction Mode</option>
                  {leadDetail?.modeOfPayment?.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </fieldset>
    
              <fieldset className="form-filed col-md-12" style={{ marginBottom: 30 }}>
                <label>Transaction Date</label>
                <FormsyDatePicker
                  name="transaction_date"
                  placeholder={"Date"}
                  value={transactionDate}
                  onDateSelect={(date) => handleTransDateChange(date)}
                  required={true}
                />
              </fieldset>
    
              <button onClick={handleTransClick} className="updatepddbtn">
                Save
              </button>
            </Formsy>
          </>
          ) : null}
        </div>
      </Modal>


      
    </>
  );
};

export default LoanStatusByStatus;
