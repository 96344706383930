import React, { useEffect, useState } from "react";
import Select from "react-select";
import generalService from "../../../service/generalService";
import MasterService from "../../../service/MasterService";
import { executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import { FINEX_MASTER } from "../../../queries/scheme.gql";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import { connect } from "react-redux";
import { toast } from "react-toastify";

const topMetroCities = ["Delhi", "New Delhi", "Noida", "Bangalore", "Mumbai", "Pune", "Hyderabad"];

const APFsearch = (props) => {
    const { updateStateIntoRedux } = props;
    const [cityList, setCityList] = useState([]);
    const [builderList, setBuilderList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [reqData, setReqData] = useState({});
    const [selectedBuilderValue, setSelectedBuilderValue] = useState(null);
    const [selectedProjectValue, setSelectedProjectValue] = useState(null);
    const [bankList, setBankList] = useState([]);
    const [feedbackText, setFeedbackText] = useState("")
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showFeedbackInput, setShowFeedbackInput] = useState(false);
    const [activeButton, setActiveButton] = useState("");


    useEffect(() => {
        const updateReduxData = async () => {
            const loginResponse = secureStorage.getItem("loginUserInfo");
            console.log(loginResponse.data.user_id, "user id")
            if (loginResponse) {
                updateStateIntoRedux("LOGIN_USER", loginResponse);
            }
        };
        updateReduxData();
    }, [updateStateIntoRedux]);

    useEffect(() => {
        const fetchCityList = async () => {
            try {
                let response = await generalService.stateCityList();
                const city = response.data.data.city;
                const filteredCities = city.filter((city) => topMetroCities.includes(city.name));
                const remainingCities = city.filter((city) => !topMetroCities.includes(city.name));
                const sortedCityList = [...filteredCities, ...remainingCities];
                setCityList(sortedCityList);
            } catch (error) {
                console.error("Error fetching city list:", error);
            }
        };

        fetchCityList();
    }, []);

    useEffect(() => {
        if (reqData.cityId) {
            const fetchBuilderList = async () => {
                try {
                    const obj = { cityId: reqData.cityId };
                    let response = await MasterService.get("/partner/apf-controller/apf-builder", obj);
                    setBuilderList(response.data.data);
                } catch (error) {
                    console.error("Error fetching builder list:", error);
                }
            };

            fetchBuilderList();
        }
    }, [reqData.cityId]);


    useEffect(() => {
        if (reqData.builder) {
            const fetchProjectList = async () => {
                try {
                    const obj = {
                        cityId: reqData.cityId,
                        builderId: reqData.builder.builder_id,
                    };
                    let response = await MasterService.get("/partner/apf-controller/apf-project", obj);
                    setProjectList(response.data.data);
                } catch (error) {
                    console.error("Error fetching project list:", error);
                }
            };

            fetchProjectList();
        }
    }, [reqData.builder]);

    const closeFeedbackModal = () => {
        setShowFeedbackModal(false);
        setShowFeedbackInput(false);
        setFeedbackText("");
    };

    const handleThumbsUp = () => {
        closeFeedbackModal();
        handleFeedbackSubmit('positive');
        setActiveButton("yes");
    };

    const handleThumbsDown = () => {
        setShowFeedbackInput(true);
        setActiveButton("no");
    };
    const handleFeedbackSubmit = (feedbackType1) => {
        // if (feedbackType1 === 'negative' && !feedbackText.trim()) {
        //     toast.error("Please provide your feedback before submitting.");
        //     return;
        // }

        const loginResponse = secureStorage.getItem("loginUserInfo");
        const userId = loginResponse.data.user_id;
        const payload = {
            user_id: userId,
            city_id: reqData.cityId,
            builder_id: reqData.builder?.builder_id,
            project_id: reqData.project_type?.project_id,
            feedback_comment: feedbackText,
            feedback_type: feedbackType1,
            bank_id: reqData.project_type?.bank_id
        };

        MasterService.post('/partner/apf-controller/save-apf-feedback', payload)
            .then((response) => {
                if (response.status === 200) {
                    toast.success("Thank you for your feedback!");
                    closeFeedbackModal();
                    setFeedbackText("");
                    setActiveButton("")

                } else {
                    toast.error("Something went wrong while submitting feedback.");
                }
            })
            .catch((error) => {
                toast.error("Error submitting feedback.");
                console.error("Error submitting feedback:", error);
            });
    };


    const handleChange = (field, value) => {
        if (field === "city") {
            setReqData((prev) => ({ ...prev, city: value.name, cityId: value.id, cityName: value.name }));
            setBuilderList([]);
            setSelectedBuilderValue(null);
            setProjectList([]);
            setSelectedProjectValue(null);


        } else if (field === "builder") {
            setReqData((prev) => ({ ...prev, builder: value }));
            setSelectedBuilderValue(value);
            setProjectList([]);
            setSelectedProjectValue(null);


        } else if (field === "project_type") {
            setReqData((prev) => ({ ...prev, project_type: value }));
            setSelectedProjectValue(value);


        }
    };

    const getBankId = async () => {
        if (reqData.city && reqData.builder && reqData.project_type) {
            const obj = {
                cityId: reqData.cityId,
                builderId: reqData.builder.builder_id,
                projectId: reqData.project_type.project_id,
            };

            let response = await MasterService.get("/partner/apf-controller/apf-bank", obj);
            if (response && response.data && response.data.data) {
                const result = response.data.data;
                const bankarr = result.map((data) => data.bank_id);
                getbankList(bankarr);
                setTimeout(() => {
                    setShowFeedbackModal(true);
                }, 2000)
            }
        }
    };

    const getbankList = async (bankarr = []) => {
        try {
            const masterData = await executeGraphQLQueryFinex(FINEX_MASTER());

            const filteredBanks = filterUniqueByBankId(masterData?.data?.masterdata?.bank_list.filter((bank) =>
                bankarr.includes(bank.id)
            ));
            setBankList(filteredBanks);
        } catch (err) {
            console.log(err);
        }
    };

    const filterUniqueByBankId = (data) => {
        const uniqueBankIds = [];
        return data.filter(item => {
            if (!uniqueBankIds.includes(item.bank_id)) {
                uniqueBankIds.push(item.bank_id);
                return true;
            }
            return false;
        });
    };

    const handleReset = () => {
        window.location.reload();
    };

    return (
        <>
            <div className="mytoolscard">
                <div className="leadheading">APF Finder</div>
                <div className="apfsearchconta">
                    <div className="col-sm-3">
                        <label className="labelsize">City</label>
                        <Select
                            placeholder={"Select City"}
                            id="City"
                            onChange={(data) => handleChange("city", data)}
                            options={cityList}
                            label={"Select City"}
                            name="City"
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                        />
                    </div>
                    <div className="col-sm-3">
                        <label className="labelsize">Builder</label>
                        <Select
                            placeholder={"Builder Name"}
                            id="Builder"
                            onChange={(data) => handleChange("builder", data)}
                            options={builderList}
                            label={"Select Builder Name"}
                            name="Builder"
                            value={selectedBuilderValue}
                            getOptionLabel={({ builder_name }) => builder_name}
                            getOptionValue={({ builder_id }) => builder_id}
                        />
                    </div>
                    <div className="col-sm-3">
                        <label className="labelsize">Project</label>
                        <Select
                            placeholder={"Project Type"}
                            id="project_type"
                            onChange={(data) => handleChange("project_type", data)}
                            options={projectList}
                            label={"Select Project Type"}
                            name="project_type"
                            value={selectedProjectValue}
                            getOptionLabel={({ project_name }) => project_name}
                            getOptionValue={({ id }) => id}
                        />
                    </div>
                    <div className="col-md-2 ">
                        <div className="btn-submit-reset top-btn-none">
                            <button
                                type="submit"
                                className="btn btn-primary mrg-r10"
                                onClick={() => {
                                    getBankId();
                                }}
                                disabled={!selectedProjectValue}

                            >
                                {"Search"}
                            </button>
                            <button
                                type="reset"
                                className="btn btn-primary mrg-r10"
                                onClick={handleReset}
                                disabled={!builderList.length > 0}
                            >
                                {"Reset"}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="apfsearchconta2">
                    <div className="details-container">
                        {reqData.cityName ? (
                            <div className="details-row">
                                <div className="detail-item">
                                    <strong>City:</strong>
                                    <div className="apiDiv">{reqData?.cityName ?? ''}</div>
                                </div>
                                <div className="detail-item">
                                    <strong>Builder:</strong>
                                    <div className="apiDiv">
                                        {selectedBuilderValue?.builder_name ?? ''}
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <strong>Project:</strong>
                                    <div className="apiDiv">
                                        {selectedProjectValue?.project_name ?? ''}
                                    </div>
                                </div>
                                <div className="detail-item">
                                    <strong>Lenders:</strong>
                                    <div className="bank-list">
                                        {bankList &&
                                            bankList.map((bank) => (
                                                <div key={bank.id} className="bank-item">
                                                    <div className="bank-logo">
                                                        {bank.logo ? (
                                                            <img src={bank.logo} alt="Bank Logo" />
                                                        ) : (
                                                            <span>No Logo Available</span>
                                                        )}
                                                    </div>
                                                    <div>{bank.banklang.bank_name}</div>
                                                </div>
                                            ))}
                                    </div>
                                </div>

                                {showFeedbackModal && (
                                    <div className="modalPOPUP">
                                        <div className="modalfeedback">
                                            <div className="crossfeedback">
                                                <p className="expFeebck">Rate Your Experience</p>
                                                <span onClick={closeFeedbackModal} className="crosssign">X</span>
                                            </div>
                                            <div className="firstSectionFedback">
                                                <p className="apftopline">Did you find the results useful?</p>
                                                <p>Your feedback is invaluable to help us get better!</p>
                                                <div className="thumbContainer">

                                                    <button
                                                        className={`thumbs-up-button ${activeButton === "yes" ? "active" : ""}`}
                                                        onClick={handleThumbsUp}
                                                    >
                                                        <span className="thumbupfeeback"> &#128077;
                                                        </span>yes
                                                    </button>
                                                    <button
                                                        className={`thumbs-down-button ${activeButton === "no" ? "active" : ""}`}
                                                        onClick={handleThumbsDown}
                                                    >
                                                        <span className="thumbupfeeback"> 👎 </span>no
                                                    </button>
                                                </div>
                                            </div>
                                            {showFeedbackInput && (
                                                <div className="feedback-input">
                                                    <div className="textcontfeback">
                                                        <label className="labelsize">Remarks</label>
                                                        <textarea
                                                            id="feedback"
                                                            className="FeedbackInput"
                                                            value={feedbackText}
                                                            onChange={(e) => setFeedbackText(e.target.value)}
                                                            placeholder="Enter your feedback here"
                                                            rows="4"
                                                        />

                                                    </div>
                                                    <button
                                                        disabled={!feedbackText}
                                                        className="btn btn-primary mrg-r10"
                                                        onClick={() => handleFeedbackSubmit("negative")}>
                                                        Submit

                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                            </div>
                        ) : (
                            <div className="no-city-message">Please select Data</div>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
    updateStateIntoRedux: (actionType, stateData) =>
        dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("common")(
    connect(mapStateToProps, mapDispatchToProps)(APFsearch)
);

