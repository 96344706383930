import { gql } from "@apollo/client";

export const LEADS_QUERY=(filterData)=>{
    return gql`query{
        leads(
            page_no:${filterData.page_no},
            user_id:${filterData.user_id},
            role_id:1,
            api_called_by:web,
            filter_params :{
                ${!!filterData.date_type ? `date_type: "${filterData.date_type}"` : ''}
                ${!!filterData.from_date ? ` , from_date: "${filterData.from_date}"` : ''}
                ${!!filterData.to_date ? ` , to_date: "${filterData.to_date}"` : ''}
                ${+filterData.status_id ? ` , status_id: [${filterData.status_id}]` : []}
                ${+filterData.sub_status_id ? ` , sub_status_id: [${filterData.sub_status_id}]` : []}
                ${!!filterData.bank_id ? ` , bank_id: [${filterData.bank_id}]` : []}
                ${!!filterData.search_text ? ` , search_text: "${filterData.search_text}"` : ''}
                ${!!filterData.lead_source ? ` , lead_source: "${filterData.lead_source}"` : ''}
                ${!!filterData.fulfillment_type ? ` , fulfillment_type: "${filterData.fulfillment_type}"` : ''}
                ${!!filterData.column ? ` , column: "${filterData.column}"` : ''}
                ${!!filterData.sort ? ` , sort: "${filterData.sort}"` : ''}
                ${!!filterData.doc_status ? ` , doc_status: "${filterData.doc_status}"` : ''}
                ${!!filterData.search_type ? ` , search_type: "${filterData.search_type}"` : ''}
                ${!!filterData.disbursement ? ` , disbursement: "${filterData.disbursement}"` : ''}
                ${!!filterData.action_type ? ` , search_type: "${filterData.action_type}"` : ''}
                ${!!filterData.rm_id ? ` , rm_id: [${filterData.rm_id}]` : []}
                ${!!filterData.verification_status ? ` , verification_status: "${filterData.verification_status}"` : ''}
                ${!!filterData.followup_type ? ` , followup_type: "${filterData.followup_type}"` : ''} 
                ${!!filterData.loan_type ? ` , loan_type: ${filterData.loan_type}` : ''}
                ${!!filterData.loan_sub_type ? ` , loan_sub_type: ${filterData.loan_sub_type}` : ''}
                ${!!filterData.property_type ? ` , property_types: ${filterData.property_type}` : ''}
                ${!!filterData.tranche_type ? ` , tranche_type: "${filterData.tranche_type}"` : ''}  
                ${!!filterData.partner_id ? ` , partner_id: ${filterData.partner_id}` : ''}
                ${!!filterData.is_demo ? ` , is_demo: "${filterData.is_demo}"` : ''}
                ${!!filterData.login_number ? ` , login_number: ${filterData.login_number}` : ''}
                ${!!filterData.approval_number ? ` , approval_number: ${filterData.approval_number}` : ''}
                ${!!filterData.disbursed_id ? ` , disbursed_id: ${filterData.disbursed_id}` : ''}
                ${!!filterData.source ? ` , source: ${filterData.source}` : ''}
                ${!!filterData.is_revisit ? ` , is_revisit: ${filterData.is_revisit}` : ''}
                ${!!filterData.is_otp_verified ? ` , is_otp_verified: ${filterData.is_otp_verified}` : ''}
            }
        ){
            lead_list{
                id 
                fulfillment_type
                is_login_verified
                is_sanction_verified
                is_status_verified
                is_disbursal_verified
                created_date
                updated_date
                sub_status_id 
                followup_date
                followup_type
                loan_type
                loan_sub_type
                status_id
                partner_name
                followup_status          
                status_info{
                    statuslang{
                        status_name
                    }
                }
                sub_status_info{
                    substatuslang{
                        sub_status_name
                    }
                } 
                customer{
                    first_name
                    last_name
                    mobile
                }
                co_applicant{
                    ca_first_name
                    ca_last_name
                    ca_mobile
                }
                rmdetails{
                    label
                    mobile
                } 
                splitpayment{
                    disbursed_date
                    disbursed_amount
                }         
                lead_details{
                    bank{
                        id
                        banklang{
                            bank_name
                        }
                    }
                    tenure
                    login_date
                    approval_date            
                    loan_amount
                    login_amount
                    approved_amount
                    annual_income
                }
            }
            pagination{
                page_no,
                totalrecords,
                nextpage,
                prevpage,
                totalpage
            } 
        }
    }`;
}

export const LEADS_COUNT_DATA = (filterData) => gql`
    query{
        leads(
            page_no: 2
            api_called_by: partner
            filter_params: {
                search_text: "10053" , 
                column: "last_updated_date", 
                sort: "desc", 
                search_type: "partner_id"
            }
        )
        {
            lead_list {
                id
                fulfillment_type
                is_login_verified
                is_sanction_verified
                is_status_verified
                is_disbursal_verified
                created_date
                updated_date
                sub_status_id
                followup_date
                followup_type
                loan_type
                loan_sub_type
                status_id
                partner_name
                followup_status
                status_info {
                    statuslang {
                        status_name
                        __typename
                    }
                __typename
                }
                sub_status_info {
                    substatuslang {
                        sub_status_name
                        __typename
                    }
                __typename
                }
                customer {
                    first_name
                    last_name
                    mobile
                    __typename
                }
                co_applicant {
                    ca_first_name
                    ca_last_name
                    ca_mobile
                }
                rmdetails {
                    label
                    mobile
                }
                splitpayment {
                    disbursed_date
                    disbursed_amount
                }
                lead_details {
                    bank {
                        id
                        banklang {
                            bank_name
                        }
                    }
                    tenure
                    login_date
                    approval_date
                    loan_amount
                    login_amount
                    approved_amount
                    annual_income
                }
            }
            pagination {
                page_no
                totalrecords
                nextpage
                prevpage
                totalpage
            }
        }
    }
`;

export const LEAD_DETAIL_QUERY=(lead_id)=>gql`
query{
    get_lead_detail(api_called_by:web,lead_id:${lead_id}){
        id
        partner_id
        assign_to
        is_login_verified
        is_sanction_verified
        reject_reason_id
        rejection_type
        is_disbursal_verified
        partner_name
        created_by
        loan_type
        loan_sub_type
        bucket_id
        tranche_type
        fulfillment_type
        followup_date
        followup_type
        followup_status 
        doc_status  
        status_id 
        sub_status_id
        last_sub_status_id
        is_qualified
        docs_collected
        disbursement_done 
        rmdetails{
            label
            mobile
        }  
        co_applicant{
            id
            ca_mobile
            ca_email
            ca_dob
            ca_type
            status
            same_as_cus_addr
            ca_first_name
            ca_last_name
            ca_gender
            ca_house_type
            ca_mother_name
            ca_father_name
            ca_nationality
            ca_qualification
            ca_pa_city
            ca_pa_house_number
            ca_pa_sameas_cra
            ca_pa_state
            ca_pa_street
            ca_pa_zipcode
            ca_pancard_no
            ca_alt_phone
            ca_aadhar_no
            ca_marital_status
            ca_existing_emi
            ca_existing_emi_amount
            ca_emi_ending_six_month
            ca_gross_monthly_salary
            ca_monthly_salary
            ca_is_obligation
            ca_annual_income
            ca_offer_type
            ca_no_of_accounts
            ca_profession
            ca_salary_credit_mode
            ca_company_name
            relationship_with_customer
            ca_company_type
            ca_loan_amount
            ca_tenure
            ca_no_of_emi
            ca_property_type
            ca_property_value
            ca_usage_type
            ca_agreement_type
            ca_cra_pincode
            ca_cra_address1
            ca_cra_address2
            ca_cra_city
            ca_cra_state
            ca_is_additional_income
            ca_additional_income{
                id
                lead_id
                customer_id
                customer_type
                income_type_id
                amount_type
                amount
                status
            }
            recommended_docs{
                id
                lead_id
                doc_id
                parent_doc_id
                is_doc_uploaded
                updated_by_source
                status
                doc_upload_url
                customer_type
                customer_id
                is_recommended
            }
            ca_offer_itr_details{
                id
                lead_id
                is_itr_filled
                npat
                depreciation
                interest
                tax_paid
                itr_year
                customer_id
                customer_type
            }
            ca_offer_bank_details{
                id
                lead_id
                account_type
                amount
                customer_id
                customer_type
            }
            employment_details{
                ca_business_address
                ca_company_phone
                ca_designation
                ca_employer_name
                ca_gross_monthly_income
                ca_industry
                ca_year_with_company
                co_applicant_id
                }
                bank_details{
                ca_account_name
                ca_account_number
                ca_account_type
                ca_bank_id
                ca_branch_name
                co_applicant_id
                ca_branch_name
                }
            __typename
        }
        customer{
            customer_id
            mobile
            email
            pancard_no
            aadhar_no
            first_name
            last_name
            dob
            pa_pincode
            gender
            no_of_dependent
            pa_house_number
            pa_street
            pa_sameas_cra
            cra_house_number
            cra_city
            cra_state
            cra_street
            cra_pincode
            cra_address1
            cra_address2
            house_type
            father_name
            mother_name
            marital_status
            offer_type
            existing_emi_amount
            emi_ending_six_month
            qualification
            no_of_accounts
            business_vintage_years
            industry
            offer_itr_details{
                id
                lead_id
                is_itr_filled
                npat
                depreciation
                interest
                tax_paid
                itr_year
                customer_id
                customer_type
            }
            offer_bank_details{
                id
                lead_id
                account_type
                amount
                customer_id
                customer_type
            }
            duration_of_stay
             is_additional_income
            recommended_docs{
            id
            lead_id
            doc_id
            parent_doc_id
            is_doc_uploaded
            updated_by_source
            status
            doc_upload_url
            customer_type
            customer_id
            is_recommended
        }
      salutation
      additional_income {
        id
        lead_id
        customer_id
        customer_type
        income_type_id
        amount_type
        amount
        status
        __typename
      }
      is_obligation
            employment{
                employer_name
                business_address
                company_phone
                designation
                industry
                gross_monthly_income
                year_with_company
                telephone_number
                __typename
            }
            bank_details {
                bank_id
                branch_name
                account_name
                account_type
                account_number
                __typename
            }
        __typename
        }
        lead_details {
      bank {
        id
        dsa_code
        banklang {
          bank_name
          __typename
        }
        __typename
      }
      lead_id
      bank_id
      login_amount
      annual_income
      no_of_emi
      tenure
      loan_amount
      existing_emi
      company_name
      profession
      salary_credit_mode
      existing_emi_amount
      is_property_identified
      usage_type
      agreement_type
      property_type
      property_sub_type
      property_agreement_value
      expected_property_value
      property_value
      property_city
      property_state
      property_address1
      property_address2
      property_pincode
      occupancy_status
      property_usage
      login_number
      approval_date
      login_date
      loan_amount
      approved_emi
      approval_date
      approval_number
      approved_amount
      monthly_salary
      cibil_score
      is_calling
      call_status
      calling_tag
      company_type
      company_name
      is_itr_filled
      is_form_16_filled
      gross_monthly_salary
      work_experience
      calling_reason_id
      subvension_type_id
      subvension_amount
      cross_sell_type_id
      cross_sell_product_amount
      project_name
      builder_name_id
      check_oc_cc
      ready_for_registration
      gross_monthly_salary
      
      emi_ending_six_month
      expected_login_date
      expected_approved_date
      expected_disbursed_date
      lod_applied_date
      lod_apply
      offer_share_date
      house_item_value
      approved_roi
      is_offer
      offer_bank
      offer_loan_amount
      offer_roi
      __typename
    }
        checklists{
            id
            lead_id
            checklist_id
            sub_status_id
            checklist_complete_date
            checklist_sub_status_id
        }
        splitpayment {
      id
      lead_id
      product_sub_type
      disbursed_id
      disbursed_roi
      disbursed_date
      disbursed_amount
      disbursed_tenure
      disbursed_date
      is_tranch_verified
      transaction_done
      transaction_mode
      transaction_date
      transaction_id
      pdd_status
      pdd_remark
      pdd_date
      pay_in_done
      pay_in_confirm_mode
      pay_in_confirm_date
      pay_in_confirm_amount
      is_transaction_verified
      is_pdd_verified
      is_payout_verified
      pay_in_confirm_id
      disbursal_remark
      product_sub_type
      pending_pdd_status {
        id
        name
        value
        status
        date
        allow_update
        is_verified
        payout_status
        payout_date
        payout_expected_date
        __typename
      }
      __typename
    }
        lead_status_history{
            status_id
            sub_status_id
        __typename
        }
        status_info{
            statuslang{
                status_name
            __typename
            }
        __typename
        }
        sub_status_info {
            substatuslang {
                sub_status_name
                __typename
            }
        __typename
        }
        rmdetails{
            id
            label
            mobile
        }
        leaddocs{
            id
            lead_id
            doc_id
            parent_doc_id
            doc_path
            doc_status
            customer_type
            customer_id
            ext
            docmaster {
                __typename
            }
        __typename
        }
    }
}`;

export const LEADS_HISTORY_QUERY=(lead_id)=>gql`
query{
    get_history(lead_id:${lead_id}, api_called_by: partner){
        id
        created_date
        history_type
        rejection_type
        title
        reason_name
        user_details{
        added_by_name
        }
        checklist_details{
        id
        checklist_name
        }
        checklist_sub_status_details{
            name
        }
        status_details{
        statuslang{
            status_name
        }
        }
        sub_status_details{
        substatuslang{
            sub_status_name
        }
        }
        followup_details{
        comment      
        }
    }
}`;

export const UPDATE_LEAD_STATUS = gql`mutation update_lead_status($UpdateLeadStatus: UpdateLeadStatus!, $api_called_by: API_CALL_BY! , $partner_id : Float){
    update_lead_status(updateleadstatus: $UpdateLeadStatus, api_called_by: $api_called_by , partner_id : $partner_id) {
      message
    }
  }`;

  export const GET_DOCUMENTS_LIST_UPDATED = (docInput) => gql`
  query{
    get_doc_list(docInput: {
      property_type_id: ${docInput?.property_type_id || 0},
      property_sub_type_id: ${docInput?.property_sub_type_id || 0},
      profession_type_id:${docInput?.profession_type_id || 0},
      product_type_id:${docInput?.product_type_id || 0},
      product_sub_type_id:"${docInput?.product_sub_type_id || ""}"
    }, api_called_by: web)
  }
`;

export const GET_DOCUMENTS_LIST = (
  property_type_id,
  profession_type_id,
  loan_type_id,
  bank_id = null,
  city_id = null
) => gql`
  query{
      get_document_list(api_called_by: partner,property_type_id:${property_type_id},profession_type_id:${profession_type_id},loan_type_id:${loan_type_id},bank_id:${bank_id},city_id:${city_id}) {
        id
        parent_id
        is_coapplicant
        is_financial
        is_required
        is_required_app
        show_in_app
        min_upload
        max_upload
        tab_name
        doclang {
          name
          doc_id
        }
        child {
          id
          parent_id
          is_required
          is_required_app
          show_in_app
          min_upload
          max_upload
          doclang {
            name
            doc_id
          }
        }
    }
  }
`;

export const CREATE_FOLLOWUP = gql`
  mutation saveFollowup(
    $CreateFollowupInput: CreateFollowupInput!
    $api_called_by: API_CALL_BY!
    $partner_id: Float!
  ) {
    save_followup(
      createFollowupInput: $CreateFollowupInput
      partner_id: $partner_id
      api_called_by: $api_called_by
    ) {
      lead_id
      comment
      followup_date
      followup_type
      followup_with
      __typename
    }
  }
`;

export const GET_FOLLOWUP = (leadId, userId) => gql`
query {
    get_followup(lead_id: ${leadId}, api_called_by: partner, user_id: ${userId}) {
      id
      followup_date
      followup_with
      followup_type
      followup_status
      comment
    }
  }`;

export const MARK_AS_DONE = gql`
  mutation updateFollowup(
    $UpdateFollowupInput: UpdateFollowupInput!
    $api_called_by: API_CALL_BY!
    $partner_id: Float!
  ) {
    update_followup(
      updateFollowupInput: $UpdateFollowupInput
      api_called_by: $api_called_by
      partner_id: $partner_id
    ) {
      message
    }
  }
`;

export const EMI_FORM_FIELD = gql`
  query {
    emi_cal_form
}`;

export const EMI_CALCULATOR=({loan_amount,loan_tenure,roi})=>gql`
  query{
    emi_calcultor(loan_amount: ${+loan_amount},loan_tenure:${+loan_tenure},roi:${+roi}) 
}`;
