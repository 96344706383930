import React, { useState } from 'react';
import NotesViewDetailStrip from './NotesViewDetailStrip';
import moment from 'moment';
import { formatCurrency } from '../../MyLeads/helpers/helpers';

const NotesCard = ({ type, togglePopup, data, bankList }) => {
    const [showDetail, setShowDetail] = useState(false);

    // Helper to construct the URL with query parameters
    const buildApproveInvoiceUrl = () => {
        let url = data.approved_invoice_url || data.pending_invoice_url;
        if (!url) url =  '#';

        const queryParams = new URLSearchParams({
            url: encodeURIComponent(url),
            invoice_id: data.id,
            invoice_no: data.invoice_no,
            created_at: moment(data.created_at).format("DD/MM/YYYY"),
            invoice_date: moment(data.invoice_date).format("DD/MM/YYYY"),
            invoice_address: data.invoice_address,
            address: data.address,
            invoice_approval: data.invoice_approval,
            partner_address: data.partner_address,
            invoice_type: data.invoice_type
        });

        return `/approve-invoice?${queryParams.toString()}`;
    };
    // console.log(">>>>", userData.mobile);
    return (
        <div>
            <p className='notes-header'>{`1`} {type} Note{`(s)`}</p>
            <div className='notes-head'>
                <div className='flex flex-row justify-space-between notes-inner-box'>
                    <div className='flex gap-10px'>
                        <div className='invoice-icon'>
                            <img
                                src={
                                    data?.leads.length
                                        ? bankList.find(item => item.id == data?.leads[0]?.financer_id)?.logo
                                        : require('../../images/docimg.svg')
                                }
                                width={"20px"}
                                height={"20px"}
                                alt="Bank Logo"
                            />
                        </div>
                        <div className='flex flex-col'>
                        <div className='font-13px font-weight-500'>
                            {`${data.invoice_type === 'credit' ? 'CN-' :  data.invoice_type === 'debit' ? 'DN-' : ""}${String(data.invoice_no).slice(-5)}`}
                        </div>
                        <div className='font-11px font-weight-400'>
                            Raised on: {moment(data.created_at).format("DD/MM/YYYY")}
                        </div>
                        </div>

                    </div>
                    <div>
                        <div className='font-13px font-weight-500'>₹ {formatCurrency(data.payout_amount)}</div>
                        {data.invoice_approval === 1 ? (
                            <div className='invoiceTag'>Approved</div>
                        ) : (
                            <div className='invoiceTagpending'>Approval Pending</div>
                        )}
                    </div>
                </div>
                <div className='flex justify-space-between notes-bottom'>
                    <div>
                        <a href={buildApproveInvoiceUrl()} className='font-btn-color'>
                            View Note
                        </a>
                    </div>
                    <div className='font-btn-color' onClick={() => setShowDetail(!showDetail)}>
                        View Details
                        <img
                            src={require('../../images/accordianicon.svg')}
                            width={"12px"}
                            alt="Toggle Details"
                            className={`accordion-icon ${showDetail ? 'rotate' : ''}`}
                        />
                    </div>
                </div>
                {showDetail &&
                    data.leads?.length &&
                    data.leads.map((leadsData) => (
                        <NotesViewDetailStrip
                            key={leadsData.id}
                            bankList={bankList}
                            togglePopup={togglePopup}
                            leadsData={leadsData}
                            data={data}
                        />
                    ))}
            </div>
        </div>
    );
};

export default NotesCard;
