import React, { useEffect, useState } from "react";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import Q from "q";
import GeneralService from "../../service/generalService";
import { toast } from "react-toastify";
import { gql } from "@apollo/client";
import Modal from "../../view/common/Modal";

const DocumentDRESection = ({
  leadDetail,
  activeTab,
  reqDocumentList,
  addDocumentList,
  setRecommendDocList,
  existingReqDocList,
  stateUpdate,
}) => {
  const [toggleVisiblity, setToggleVisiblity] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [existingRecommendedList, setExistingRecommendedList] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [docsToRemove, setDocsToRemove] = useState([]);
  const [docPreviewModalVisiblity, setDocPreviewModalVisiblity] =
    useState(false);

  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));

  let name = loginUserInfo?.name ? loginUserInfo.name : "";
  let userId =
    loginUserInfo && loginUserInfo.user_data
      ? loginUserInfo.user_data.user_id
      : 1;

  useEffect(() => {
    let customer_type = "customer";
    if (activeTab.indexOf("co") > -1) {
      customer_type = "co_applicant";
    }
    const filteredList = existingReqDocList.filter(
      (obj) => obj.customer_type === customer_type
    );
    setExistingRecommendedList(filteredList);
  }, [activeTab, existingReqDocList]);

  const handleImageUpload = (event) => {
    let files = Array.from(event.target.files),
      awsRes = [],
      leadDocArr = [];
    const lead_id = leadDetail?.id;
    const doc_id = +event.target.getAttribute("docid");
    const parent_doc_id = +event.target.getAttribute("parentdocid");
    let customer_id = +leadDetail?.customer?.customer_id;
    let customer_type = "customer";
    if (activeTab.indexOf("co") > -1) {
      customer_id = +activeTab.split("-")[1];
      customer_type = "co_applicant";
    }
    if (files && files.length) {
      setIsLoading(true);
      var formData = new FormData();
      formData.append("dealer_id", lead_id);
      formData.append("upload_type", "dealer_docs");
      for (let imgFile of event.target.files) {
        formData.append("images", imgFile);
      }
      awsRes.push(
        GeneralService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        })
      );
    } else {
      awsRes.push(
        Promise.resolve({
          data: { status: 200, message: "Success", data: ["not_updated"] },
        })
      );
    }

    Q.allSettled(awsRes).then((uploadOutput) => {
      uploadOutput.forEach((res, index) => {
        let response = res.value || [];
        if (res.state === "fulfilled") {
          if (response.data.data.length) {
            let resData = response.data.data;
            resData.forEach((row, i) => {
              let loanDetailsSaveDoc = {
                lead_id,
                doc_path: row.file_url,
                parent_doc_id,
                doc_id,
                doc_source: "web",
                user_id: userId || 0,
                customer_type,
                customer_id,
              };
              leadDocArr.push(loanDetailsSaveDoc);
            });
          }
        }
      });
      if (leadDocArr.length) {
        let variables = {
          saveDocInput: {
            lead_id,
            lead_docs: leadDocArr,
          },
          api_called_by: "saathi_web",
          partner_id: loginUserInfo?.dealer_id,
        };

        let mutation = gql`
          mutation save_doc(
            $saveDocInput: SaveDocInput!
            $api_called_by: API_CALL_BY!
            $partner_id: Float!
          ) {
            save_doc(
              saveDocInput: $saveDocInput
              api_called_by: $api_called_by
              partner_id: $partner_id
            ) {
              lead_id
              doc_id
              doc_path
            }
          }
        `;

        executeGraphQLMutationFinex(mutation, variables)
          .then((result) => {
            if (result && result.data) {
              setIsLoading(false);
              toast.success("Document uploaded successfully");
              stateUpdate(true);
              setDocPreviewModalVisiblity(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      } else {
        toast.error("Error in image upload!");
        setIsLoading(false);
      }
    });
  };

  const handleToggleClick = (id) => {
    setToggleVisiblity((prev) => ({
      ...prev,
      [id]: !toggleVisiblity[id],
    }));
  };

  const handleDocCheck = (event) => {
    if (event.target) {
      const { checked } = event.target;
      const lead_id = leadDetail?.id;
      const doc_id = +event.target.getAttribute("docid");
      const parent_doc_id = +event.target.getAttribute("parentdocid");
      const existingDocList = [...existingRecommendedList];
      let customer_id = +leadDetail?.customer?.customer_id;
      let customer_type = "customer";
      if (activeTab.indexOf("co") > -1) {
        customer_id = +activeTab.split("-")[1];
        customer_type = "co_applicant";
      }
      const currentDocIndex = existingDocList.findIndex(
        (obj) =>
          obj.lead_id === lead_id &&
          obj.parent_doc_id === parent_doc_id &&
          obj.doc_id === doc_id &&
          obj.customer_id === customer_id &&
          obj.customer_type === customer_type
      );

      if (currentDocIndex > -1) {
        const currentDoc =
          existingDocList.find(
            (obj) =>
              obj.lead_id === lead_id &&
              obj.parent_doc_id === parent_doc_id &&
              obj.doc_id === doc_id &&
              obj.customer_id === customer_id &&
              obj.customer_type === customer_type
          ) || {};

        const updatedObj = {
          ...currentDoc,
          status: checked ? "1" : "0",
          is_recommended: checked ? 1 : 0,
        };
        existingDocList.splice(currentDocIndex, 1);
        existingDocList.push(updatedObj);
      } else {
        existingDocList.push({
          lead_id,
          doc_id,
          parent_doc_id,
          customer_id,
          customer_type,
          status: checked ? "1" : "0",
          is_recommended: checked ? 1 : 0,
        });
      }
      setExistingRecommendedList(existingDocList);
      setRecommendDocList(existingDocList);
    }
  };

  const handleDocView = (event) => {
    const doc_id = +event.target.getAttribute("docid");
    const parent_doc_id = +event.target.getAttribute("parentdocid");
    let customer_id = +leadDetail?.customer?.customer_id;
    let customer_type = "customer";
    if (activeTab.indexOf("co") > -1) {
      customer_id = +activeTab.split("-")[1];
      customer_type = "co_applicant";
    }
    const uploadedDocs = leadDetail?.leaddocs?.filter(
      (obj) =>
        obj.parent_doc_id === parent_doc_id &&
        obj.doc_id === doc_id &&
        obj.customer_id === customer_id &&
        obj.customer_type === customer_type
    );
    if (!docPreviewModalVisiblity) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    setUploadedDocs(uploadedDocs);
    setDocPreviewModalVisiblity(!docPreviewModalVisiblity);
  };

  const handleOpenNewTab = (event) => {
    const { src } = event.target;
    if (src) {
      window.open(src, "_blank");
    } else {
      const pdfUrl = event.target.getAttribute("data-docurl");
      window.open(pdfUrl, "_blank");
    }
  };

  const handleDocCheckToRemove = (event) => {
    const { checked } = event.target;
    const docPkId = +event.target.getAttribute("data-docpkid");
    const existingDocs = [...docsToRemove];
    if (checked) {
      existingDocs.push(docPkId);
    } else {
      const index = existingDocs.findIndex((doc) => doc === docPkId);
      existingDocs.splice(index, 1);
    }
    setDocsToRemove(existingDocs);
  };

  const handleRemoveDocument = async () => {
    try {
      const isConfirmed = window.confirm(
        "Confirm, do you want to remove these documents"
      );
      if (isConfirmed) {
        const lead_id = leadDetail?.id;
        const mutation = gql`
          mutation update_doc($updateDocInput: UpdateDocInput!) {
            update_doc(updateDocInput: $updateDocInput) {
              id
              lead_id
              doc_id
            }
          }
        `;

        const listOfPromises = docsToRemove.map((docId) => {
          const variables = {
            updateDocInput: {
              id: docId,
              lead_id,
              status: "0",
              parent_doc_id: 0,
              doc_id: 0,
              doc_source: "web",
              user_id: userId || 0,
              tab_name: "first_tab",
            },
          };
          return new Promise((resolve) =>
            resolve(executeGraphQLMutationFinex(mutation, variables))
          );
        });
        await Promise.all(listOfPromises);
        toast.success("Successfully removed");
        stateUpdate(true);
        setDocPreviewModalVisiblity(false);
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Internal Server Error");
    }
  };

  const renderDocumentSection = (documentHeading, data) => {
    const docType = documentHeading.split(" ")[0]?.toLowerCase();
    let customer_id = +leadDetail?.customer?.customer_id;
    let customer_type = "customer";
    if (activeTab.indexOf("co") > -1) {
      customer_id = +activeTab.split("-")[1];
      customer_type = "co_applicant";
    }

    if (documentHeading.indexOf("Recommended") > -1) {
      if (customer_type === "co_applicant") {
        if (existingRecommendedList.length === 0) {
          const coAppId = +activeTab.split("-")[1];
          const coAppDetails =
            leadDetail.co_applicant.find((coApp) => coApp.id === coAppId) || {};
          const caType = coAppDetails?.ca_type || "";

          if (caType === "non_financing") {
            data = data.map((ob) => {
              const childs = ob?.child?.filter(
                (chOb) => chOb.no_financial == "1"
              );
              if (childs.length > 0) {
                return { ...ob, child: childs };
              }
            });
          } else {
            const KYC_DOC_ID = 3;
            data = data.filter((ob) => {
              const childs = ob?.child?.filter(
                (chOb) =>
                  chOb.no_financial == "0" || chOb.parent_id === KYC_DOC_ID
              );
              if (childs.length > 0) {
                return { ...ob, child: childs };
              }
            });
          }
        }
      }
    }

    return (
      <>
        <h5>{documentHeading}</h5>
        <div className="row">
          {data.map((doc, index) => {
            const isUserDoc = leadDetail?.leaddocs.filter(
              (ob) =>
                ob.customer_id == customer_id && ob.parent_doc_id == doc.id
            );
            return (
              <div
                className="col-md-6"
                key={`${docType}_${index}_${doc?.id}`}
                style={{ margin: "8px 0px" }}
              >
                <div className="documentlist">
                  <div
                    className="doclistshow"
                    onClick={() =>
                      handleToggleClick(`${docType}_${index}_${doc?.id}`)
                    }
                  >
                    <div className="doclistflex">
                      <img
                        src={require(`../../../src/saathi-web/images/docimg.svg`)}
                        alt="thankyou"
                        width={12}
                      />
                      <div>{doc?.doclang?.name}</div>
                    </div>
                    <div
                      className={`rotate-icon ${
                        toggleVisiblity[`${docType}_${index}_${doc?.id}`]
                          ? "open"
                          : ""
                      }`}
                    >
                      <img
                        src={require(`../../../src/saathi-web/images/accordianicon.svg`)}
                        alt="thankyou"
                        width={12}
                      />
                    </div>
                  </div>

                  <div
                    className={`docupload ${
                      toggleVisiblity[`${docType}_${index}_${doc?.id}`]
                        ? "docshow"
                        : ""
                    }`}
                  >
                    {doc?.child?.map((docChild, childIndex) => {
                      const uploadedDocs = leadDetail?.leaddocs?.filter(
                        (obj) =>
                          obj.parent_doc_id === doc?.id &&
                          obj.doc_id === docChild?.id &&
                          obj.customer_id === customer_id &&
                          obj.customer_type === customer_type
                      );
                      const isDocUploaded = uploadedDocs.length > 0;
                      const isReq =
                        documentHeading.indexOf("Recommended") > -1
                          ? docChild?.is_required_app || "0"
                          : "0";
                      const isDisabled =
                        isReq == "1"
                          ? true
                          : isUserDoc.filter(
                              (obj) =>
                                obj.doc_id === docChild.id &&
                                obj.parent_doc_id === docChild.parent_id &&
                                obj.doc_status === 2
                            ).length > 0
                          ? true
                          : false;
                      const isChecked =
                        isReq == "1"
                          ? true
                          : existingRecommendedList?.filter(
                              (obj) =>
                                obj.parent_doc_id === doc?.id &&
                                obj.doc_id === docChild?.id &&
                                obj.customer_id === customer_id &&
                                obj.customer_type === customer_type &&
                                obj.status == "1"
                            )?.length > 0
                          ? true
                          : false;

                      return (
                        <>
                          <div
                            className="docuploadlistshow"
                            key={`${docType}_${index}_${doc?.id}_child_${childIndex}`}
                          >
                            <div className="docuploadcheck">
                              <input
                                type="checkbox"
                                checked={isChecked}
                                disabled={isDisabled}
                                id={`${docType}_${index}_${doc?.id}_child_${childIndex}`}
                                parentdocid={`${doc?.id}`}
                                docid={`${docChild?.id}`}
                                onClick={handleDocCheck}
                              />
                              <label
                                htmlFor={`${docType}_${index}_${doc?.id}_child_${childIndex}`}
                              >
                                {docChild?.doclang?.name}
                              </label>
                            </div>
                            {isDocUploaded ? (
                              <div
                                className="uploadButton"
                                onClick={handleDocView}
                                parentdocid={`${doc?.id}`}
                                docid={`${docChild?.id}`}
                              >
                                View
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  parentdocid={`${doc?.id}`}
                                  docid={`${docChild?.id}`}
                                  onChange={handleImageUpload}
                                  style={{ display: "none" }}
                                  id={`${docType}_${index}_${doc?.id}_child_${childIndex}_upload`}
                                  accept="image/*,.pdf"
                                  multiple
                                />
                                <div>
                                  <label
                                    htmlFor={`${docType}_${index}_${doc?.id}_child_${childIndex}_upload`}
                                    className="uploadButton"
                                  >
                                    Upload
                                  </label>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const documentComponent = (
    <>{renderDocumentSection("Recommended Documents", reqDocumentList)}</>
  );
  const otherDocumentComponent = (
    <>
      {addDocumentList.length > 0 &&
        renderDocumentSection("Picked Up Additional Docs", addDocumentList)}
    </>
  );

  return (
    <>
      {documentComponent}
      {otherDocumentComponent}

      <Modal
        isOpen={docPreviewModalVisiblity}
        togglePopup={handleDocView}
        width={600}
      >
        <div className="dcoheading">Supporting Docs</div>
        <div>
          <ul className="docimagecard">
            {uploadedDocs?.map((doc, index) => (
              <li target="blank" key={index}>
                <input
                  type="checkbox"
                  id={`checkbox_${index}`}
                  onClick={handleDocCheckToRemove}
                  data-docpkid={`${doc.id}`}
                />
                <label htmlFor={`checkbox_${index}`}></label>
                {doc.ext === "pdf" || doc.ext === "PDF" ? (
                  <i
                    data-docurl={doc?.doc_path}
                    className="ic-pdf-file icons-normal"
                    onClick={handleOpenNewTab}
                    style={{ fontSize: "60px" }}
                  ></i>
                ) : (
                  <img src={doc?.doc_path} onClick={handleOpenNewTab} />
                )}
              </li>
            ))}
          </ul>

          <div className="docbtnall">
            <button
              className="docremoebtn"
              disabled={docsToRemove.length ? false : true}
              onClick={handleRemoveDocument}
            >
              Remove
            </button>
            <button className="docreuploadbtn">
              <label htmlFor={`reupload`}>Re-Upload</label>
            </button>
            <input
              type="file"
              id="reupload"
              style={{ display: "none" }}
              parentdocid={`${uploadedDocs[0]?.parent_doc_id}`}
              docid={`${uploadedDocs[0]?.doc_id}`}
              onChange={handleImageUpload}
              accept="image/*,.pdf"
              multiple
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DocumentDRESection;
