import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import LeftPanel from './left-panel';
import LoginForm from './login-form';
import ContactUs from './contact-us';
import JoinUs from './join-us';
import { connect } from 'react-redux';
import secureStorage from './../../../config/encrypt';
import ForgotPassword from './ForgotPassword';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
import { useLocation } from "react-router-dom";
import loginlogo from '../../../view/login/assets/images/loginlogo.svg'
import { GET_ACCESS_FEATURES, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
// import { resetOtherPrimary } from '../../../../../dealers/src/modules/showroom/model/ShowroomModel';


const Login = (props) => {
    let queryParams = new URLSearchParams(props.location.search);
    const [fields, setFields] = useState({
        captchaVal: "",
        captchaId: "",
        otpId: "",
        otpValue: "",
    });
    const [errors, setErrors] = useState({});
    const [key, setKey] = useState(1);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(0);
    const [show, setShow] = useState(false);
    const [renderSection, setRenderSection] = useState('login');
    const [lang_id, setLangId] = useState(null);
    const [reloadCaptcha, setReloadCaptcha] = useState(1);
    const [langOption, setLangOption] = useState([]);
    const [showOtp, setShowOtp] = useState(false);
    const location = useLocation();
    const [user_id, setUserID] = useState(queryParams && queryParams!="" ? queryParams.get("user_id") : "");
   // console.log("queryParams",user_id)
    useEffect(() => {
        let localLangData = secureStorage.getItem('langOption');
        if (!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    setLangOption(langData);
                    setDefaultLanguage(null, langData);
                }
            });
        } else {
            setLangOption(localLangData);
            setDefaultLanguage(null, localLangData);
        }
    }, []);

    useEffect(() => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse);
        } 
        if(user_id && user_id!=""){
            MasterService.get('account/user/email-verification/'+user_id)
        } 
    }, [user_id]);
 

    const afterLoginSetState = (loginResponse) => {
        let role =
            loginResponse &&
            loginResponse.data &&
            loginResponse.data.user_data &&
            loginResponse.data.user_data.role ||
            null;
            
        if (role === "admin" || role === "Partner") {
            setRedirect(2);
            getUserAccess()
        } else if (role === "dealercentral") {
            setRedirect(1);
            getUserAccess()
        }
    };

     const getUserAccess = (postData, client) =>{
        return async (dispatch) => {
            try {
                let data;
                await executeGraphQLQuery(GET_ACCESS_FEATURES(postData),client).then((response) => { 
                    if (response && response.data && response.data.get_user_access_features) {
                        data = response.data.get_user_access_features;
                    }
                 })
                 .catch((error) => {
                    console.log(error);
                 });
    
                if (data && data.length) {
                    let formattedUserAccess = [];
                    // if feature exist in multiple role for same user (formatting the features array)
                    data && data.forEach(item => {
                        let exist_index = formattedUserAccess.findIndex(v => (v.product_type === item.product_type && v.action === item.action));
                        if (exist_index > -1) {
                            // To save '1' if both 1 and 0 are present
                            let newAccessType = Math.max(
                                Number(item.access_type),
                                Number(formattedUserAccess[exist_index]["access_type"])
                            );
                            formattedUserAccess[exist_index]["access_type"] = newAccessType ? newAccessType.toString() : '0'; 
                        } else {
                            formattedUserAccess.push(item);
                        }
                    }) 
                    localStorage.setItem("userAccess", JSON.stringify(formattedUserAccess));
                    return {
                        status: true,
                        response: "success",
                        data:data
                    };
                } else
                    return {
                        status: false,
                        response: "success",
                    };
            } catch (err) {
                return {
                    status: false,
                    response: err,
                };
            }
        };
    }
    

    const handleChangeEvent = (event) => {
        let updatedFields = { ...fields };
        const updatedErrors = { ...errors };
        if (event && event.captchaData && event.captchaData.status === 200) {
            updatedFields["captchaVal"] = event.captchaVal;
            updatedFields['captchaId'] = event.captchaData.data.value;
            if (event.captchaVal === "") {
                updatedErrors['captchaErr'] = 'Please enter valid captcha';
            }
        } else {
            updatedFields[event.target.name] = event.target.value;
            if (event.target.value !== '') {
                delete updatedErrors[event.target.name];
            } else {
                updatedErrors[event.target.name] = 'Please enter ' + event.target.name;
            }
        }
        setFields(updatedFields);
        setErrors(updatedErrors);
    };

    const submitAndValidateLoginForm = (event) => {
        event.preventDefault();
        let fieldsCopy = { ...fields };
        const errorsCopy = { ...errors };
        if (validForm()) {
            setLoading(true);
            fieldsCopy.withCaptcha = showOtp ? false : true;
            MasterService.post('account/user/login', fieldsCopy)
                .then((response) => {
                  //  console.log ("respomse_" , response)
                    if (response.status === 200 && response.data.status === 200 && response.data) {
                        if (fieldsCopy.withCaptcha) {
                            sendOtpEmail();
                        } else {
                            response = response.data;
                            let cityIds = (response.data &&
                                response.data.dealer_data &&
                                response.data.dealer_data[0] &&
                                response.data.dealer_data[0].city_ids)
                                ? response.data.dealer_data[0].city_ids
                                : [];
                            let localityApi =
                                cityIds.length > 0
                                    ? MasterService.post('core/commonservice/locality_list', { city: cityIds })
                                    : Promise.resolve();
                            localityApi
                                .then((locRes) => {
                                    if (
                                        locRes &&
                                        locRes.status === 200 &&
                                        locRes.data.status === 200 &&
                                        locRes.data.data
                                    ) {
                                        response.locality = locRes.data.data;
                                    }
                                    secureStorage.setItem('loginUserInfo', response);
                                    let lang_id = null;
                                    if (
                                        response.data.dealer_data &&
                                        response.data.dealer_data[0] &&
                                        response.data.dealer_data[0].default_lang_id
                                    )
                                        lang_id = response.data.dealer_data[0].default_lang_id;
                                    else if (response.data.user_data && response.data.user_data.lang)
                                        lang_id = response.data.user_data.lang;
                                    setDefaultLanguage(lang_id);
                                    props.updateStateIntoRedux('LOGIN_USER', response);
                                    afterLoginSetState(response);
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    window.commonErrorToast(error.message);
                                    setReloadCaptcha(!(reloadCaptcha));
                                });
                        }
                    } else {
                        window.commonErrorToast(response.data.message);
                        if (response.data.errors[0] && response.data.errors[0] === "ACCOUNT_BLOCKED") {
                            errorsCopy['email'] = response.data.message;
                            setErrors(errorsCopy);
                        } else {
                            delete errorsCopy['email'];
                        }
                        setLoading(false);
                        setReloadCaptcha(!(reloadCaptcha));
                        setFields({ ...fields, "captchaVal": "tesT123" });
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    window.commonErrorToast(error.message);
                });
        }
    };

    const sendOtpEmail = () => {
        let fieldsCopy = { ...fields };
        fieldsCopy.withCaptcha = showOtp ? false : true;
        let sendData = {
            email: fieldsCopy.email
        };
        MasterService.post('account/user/send_email_otp', sendData)
            .then(function (response) {
                if (response.status === 200 && response.data.status === 200) {
                    if (response.data.data && response.data.data.value) {
                        fieldsCopy["otpId"] = response.data.data.value;
                        setFields(fieldsCopy);
                        setShowOtp(true);
                        toast.success(response.data.message);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    window.commonErrorToast(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
                window.commonErrorToast(error.message);
            });
    };

    const setDefaultLanguage = (lang_id, localLangData) => {
        let lang = null;
        lang_id = lang_id || lang_id;
        localLangData = localLangData || secureStorage.getItem('langOption');
        if (lang_id) setLangId(lang_id);
        if (lang_id && localLangData && localLangData.length > 0)
            lang = localLangData.filter((v) => v.id === lang_id).map((v) => v.iso_code)[0];
        if (lang) {
            secureStorage.setItem('lang', lang);
            if (props.i18n) props.i18n.changeLanguage(lang);
        }
    };


    const validForm = () => {
        let fieldsCopy = { ...fields };
        const errorsCopy = { ...errors };
        let showOtpCopy = showOtp;
        let formIsValid = true;
        errorsCopy['captchaErrorMessage'] = '';
        if (showOtpCopy) {
            if (errorsCopy["otpValue"] || (!fieldsCopy.otpValue)) {
                if (!fieldsCopy["otpValue"]) {
                    errorsCopy['otpValue'] = 'OTP is required';
                    errorsCopy['OtpErrorMessage'] = 'OTP is required';
                } else if (errorsCopy["otpValue"]) {
                    errorsCopy['otpValue'] = 'Please enter valid OTP';
                    errorsCopy['OtpErrorMessage'] = 'Please enter valid OTP';
                }
                formIsValid = false;
            } else if (fieldsCopy.otpValue.length !== 6) {
                errorsCopy['otpValue'] = 'Please enter valid OTP';
                errorsCopy['OtpErrorMessage'] = 'Please enter valid OTP';
                formIsValid = false;
            } else if (errorsCopy["otpValue"]) {
                errorsCopy['otpValue'] = 'Please enter valid OTP';
                errorsCopy['OtpErrorMessage'] = 'Please enter valid OTP';
                formIsValid = false;
            }
        } else {
            if (!fieldsCopy["email"]) {
                errorsCopy['email'] = props.t('login.enter_email');
                formIsValid = false;
            } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fieldsCopy["email"])) {
                errorsCopy['email'] = props.t('login.valid_email');
                formIsValid = false;
            }
            if (!fieldsCopy["password"]) {
                errorsCopy['password'] = props.t('login.enter_password');
                formIsValid = false;
            }
            if (errorsCopy["captchaErr"] || (!fieldsCopy["captchaVal"])) {
                if (!fieldsCopy["captchaVal"]) {
                    errorsCopy['captchaErr'] = 'Captcha is required';
                    errorsCopy['captchaErrorMessage'] = 'Captcha is required';
                } else if (errorsCopy["captchaErr"]) {
                    errorsCopy['captchaErr'] = 'Please enter valid captcha';
                    errorsCopy['captchaErrorMessage'] = 'Please enter valid captcha';
                    setReloadCaptcha(!(reloadCaptcha));
                }
                formIsValid = false;
            }
        }
        setErrors(errorsCopy);
        return formIsValid;
    };

    const changeContent = (event) => {
        let showingSection = event.currentTarget.dataset.key;
        props.updateStateIntoRedux('CHANGE_CONTENT', showingSection);
        setRenderSection(showingSection);
    };

    const switchLanguage = (event) => {
        let lang = event.currentTarget.dataset.key;
        const { i18n } = props;
        secureStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
    };

    if (redirect === 1) {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
           // console.log("loginResponse",loginResponse)
           const dealer_id_hash =   loginResponse.data.dealer_data[0].dealer_id_hash;
          // console.log("dealer_id_hash Home",dealer_id_hash)
           if(dealer_id_hash){
            return <Redirect to={`/PartnerDashboard`} />;
           }
        } 
       
    } else if (redirect === 2) {
        return <Redirect to={'/admin/dealer-list'} />;
    }

    const contentSection = renderSection;
    let dynamicComponent = '';
    if (contentSection === 'login') {
        dynamicComponent = (
            <LoginForm
                errorMessage={errors}
                showOtp={showOtp}
                onClick={submitAndValidateLoginForm}
                onChange={handleChangeEvent}
                reloadCaptcha={reloadCaptcha}
                sendOtpEmail={sendOtpEmail}
            />
        );
    } else if (contentSection === 'forgot') {
        dynamicComponent = <ForgotPassword />;
    } else if (contentSection === 'contactus') {
        dynamicComponent = <ContactUs />;
    } else {
        dynamicComponent = <JoinUs />;
    }

    const selLang = secureStorage.getItem('lang') || 'en';

    return (
        
        <div className={loading ? "container-fluid loading" : "container-fluid"} id="homePage">
            <LeftPanel showWelcomeHeading={true}/>
            <div className="rightWhiteBg">
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginBottom:"30px"}}>
                <img src="https://static.ambak.com/saathi/ambak_logo.svg" alt='logo' width={186} height={60} />
                </div>
                {
                    user_id && user_id!="" ? 
                    <div style={{ color: 'green', fontSize: '16px',fontWeight: 'bold'}}>
                        Thank you for confirming your email address!
                    </div> : null
                } 
                <nav className="navbar" role="navigation">
                    <ul className="nav navbar-nav" style={{width:'50%'}}>
                        <li data-key="login" className={contentSection === 'login' ? 'active' : ''} onClick={changeContent}>
                            {props.t('login.login')}
                        </li>
                        <li data-key="joinus" className={contentSection === 'joinus' ? 'active' : ''} onClick={changeContent}>
                            {props.t('joinus.join_us')}
                        </li>
                        <li data-key="contactus" className={contentSection === 'contactus' ? 'active' : ''} onClick={changeContent}>
                            {props.t('login.contact_us')}
                        </li>
                        {/* {langOption.length > 1 ? (
                            <li>
                                <div className="language-change language-login">
                                    {langOption.map((lang, index) => (
                                        <button
                                            key={index}
                                            data-key={lang.iso_code}
                                            type="button"
                                            className={selLang === lang.iso_code ? 'btn btn-primary' : 'btn btn-default'}
                                            onClick={switchLanguage}
                                        >
                                            {lang.iso_code}
                                        </button>
                                    ))}
                                </div>
                            </li>
                        ) : (
                            ''
                        )} */}
                    </ul>
                </nav> 
                <section className="slide-wrapper">
                    <div id="myCarousel" className="carousel slide carousel-fade">
                        <ol className="carousel-indicators">
                            <li data-key="login" className={contentSection === 'login' ? 'active' : ''} onClick={changeContent}></li>
                            <li data-key="joinus" className={contentSection === 'joinus' ? 'active' : ''} onClick={changeContent}></li>
                            <li data-key="contactus" className={contentSection === 'contactus' ? 'active' : ''} onClick={changeContent}></li>
                        </ol>
                        <div className="carousel-inner">{dynamicComponent}</div>
                    </div>
                </section>
                {/* <div className='playStoreCommon'>
                    <div className="playStore">
                        <a href="http://play.google.com/store/apps/details?id=com.oto.dealer.id" target="_blank">
                            <img src={require('./../assets/images/google-play.png')} alt="" />
                        </a>
                        <a href="https://itunes.apple.com/us/app/oto-dealer/id1489698884?ls=1&amp;mt=8" target="_blank"> 
                            <img style={{marginLeft:10}} src={require('./../assets/images/app-store.png')} alt="" />
                        </a> 
                    </div>
                </div> */}
                {/* <footer>
                    <div className="footer">{props.t('site_content.copyright')}</div>
                </footer> */}
            </div>
        </div>
        
    );
}

const mapStateToProps = (state) => {
    return {
        user_loggedin: state.userLoggedIn
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    };
};

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Login));
