import React, { useEffect, useMemo, useState } from "react";
import { executeGraphQLMutationFinex } from "../../../../common/executeGraphQLQuery";
import { SAVE_CUST_DETAILS } from "../../../../queries/sangam/customer.gql";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";
import Select from "react-select";
import InputField from "../../../../view/elements/Input";
import { toast } from "react-toastify";
import { useCleverTap } from '../../../CleverTapContext';
import { act } from "react";

const SEP_ID = 2;

const LoanOfferHome = ({
  masterdata,
  setNextActive,
  breData,
  handleBreDataChange,
  handleDataFromLoanOffer
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [errors, setErrors] = useState({});
  const trackEvent = useCleverTap();
  const [activeTab, setActiveTab] = useState("home_loan");
  useEffect(() => {
   if (breData.loan_type == 1)
    {
      setActiveTab("home_loan")
    }
   else if (breData.loan_type == 2)
    {
      setActiveTab("loan_against_property")
    }
  }, [breData])
  const ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? masterdata : {};
    options["profession"] = options["profession"]?.filter(
      (obj) => obj.value !== SEP_ID
    );
    return options;
  }, [masterdata]);

  const handleSelectChange = (name, data) => {
    trackEvent("input_field_filled", "offer_engine_form_profession");
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    handleBreDataChange(name, data.value);
  };

  const handleInputChange = (event) => {
    trackEvent("input_field_filled", "offer_engine_form_req_amount");
    const { name, value } = event.target;
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    handleBreDataChange(name, value.replace(/[^0-9]/g, ""));
  };

  const handleNext = () => {
    trackEvent("cta_clicked", "offer_engine_form_main");
    if (!breData?.profession) {
      return setErrors((prev) => ({
        ...prev,
        profession: "Employment type is required",
      }));
    }
    if (!breData?.loan_amount || +breData?.loan_amount <= 0) {
      return setErrors((prev) => ({
        ...prev,
        loan_amount: "Loan amount is required and should be greater than 0",
      }));
    }
    handleSubmit();
  };

  const handleSubmit = async () => {
    try {
      const { lead_id, loan_amount, profession } = breData;
      const loan_type = activeTab === "home_loan" ? 1 : 2;
      if (lead_id) {
        const mutation = SAVE_CUST_DETAILS;
        const variables = {
          api_called_by: "saathi_web",
          partner_id: loginUserInfo?.dealer_id,
          LeadAdditionalInput: {
            lead_id,
            lead_details: {
              lead_id,
              loan_amount,
              profession,
              loan_type
            },
          },
        };
        await executeGraphQLMutationFinex(mutation, variables);
        toast.success("Success");
      }
      setNextActive();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // handleDataFromLoanOffer(tab)
  };
  handleDataFromLoanOffer(activeTab)
  return (
    <>
      <div className="myloanofferflex">
        <div className="loanofferleftpanle">
          <div className="loanofferheading">
            Match your
            <br /> customers with
            <span className="loanoffersub"> the right lenders</span>
          </div>
          <div className="loanoffermiddle">
            <video width="100%" height="360" autoPlay controls={false}>
              <source
                src="https://static.ambak.com/saathi/video/ambak-video.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="loanofferrightpanle">
          <div className="loanoffersubheading">
            Pick the best loan offer
            <br /> your customers!
          </div>
          <div className="loanoffermiddle">
            
            <div>What are you looking for?</div>

            <div className="cibilloanflex">
        <div
          className={`cibilhometab ${activeTab === "home_loan" ? "active" : ""}`}
          onClick={() => handleTabChange("home_loan")}
        >
          <span>Home Loan</span>
        </div>

        <div
          className={`cibilhometab ${activeTab === "loan_against_property" ? "active" : ""}`}
          onClick={() => handleTabChange("loan_against_property")}
        >
          <span>Lap </span>
        </div>
      </div>

            <div className="row">
              <div className="col-sm-12" style={{ marginBottom: 20 }}>
                <label>
                  Employment Type <span className="form-required">*</span>
                </label>
                <Select
                  required={true}
                  id="profession"
                  onChange={(data) => handleSelectChange("profession", data)}
                  options={ALL_OPTIONS?.["profession"] || []}
                  name="profession"
                  placeholder="Select Employment Type"
                  value={ALL_OPTIONS?.["profession"]?.find(
                    (option) => option.value === breData.profession
                  )}
                />
                {errors.profession && (
                  <div className="formerror">{errors.profession}</div>
                )}
              </div>
              <div className="col-sm-12">
                <InputField
                  type="text"
                  name="loan_amount"
                  id="loan_amount"
                  maxLength={12}
                  onChange={handleInputChange}
                  placeholder="Required Loan Amount"
                  label="Required Loan Amount"
                  required={true}
                  value={handlePriceInputIndian(breData["loan_amount"])}
                />
                {errors.loan_amount && (
                  <div className="formerrorinput">{errors.loan_amount}</div>
                )}
              </div>
            </div>

            <button className="getstartedbtn" onClick={handleNext}>
              Get Started!
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanOfferHome;
