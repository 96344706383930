import React from "react";




const NoDataFound = () =>{
    return(
        <>
        <div className="nodataflex">
            <div className="nodatacard">
                <img src="https://static.ambak.com/saathi/1727502314187.svg" alt="no data found" />
            
            {/* <div className="nodataheading">Lorem Ipsum has been the industry's</div> */}
            {/* <p className="nodatapara">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type</p> */}
        </div>
        </div>
        </>
    );
}



export default NoDataFound;