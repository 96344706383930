import Q from 'q';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DealerService, InventoryService } from '../../../service';
import Button from '../../elements/Button';
import InputField from '../../elements/Input';
import MasterService from '../../../service/MasterService';
import Select from 'react-select';
import Modal from '../../common/Modal';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const rejection_reason = [
    { id: 1, name: 'Personal details are wrong' },
    { id: 2, name: 'Wrong partner type is marked' },
    { id: 3, name: 'Phone number is wrong' },
    { id: 4, name: 'Email is wrong' },
    { id: 5, name: 'Address is currently unservicable' },
    { id: 6, name: 'Wrong PAN number' },
    { id: 7, name: 'Wrong aadhar' },
    { id: 8, name: 'Wrong GSTIN' },
    { id: 9, name: 'Wrong MSME' },
    { id: 10, name: 'BM not available' },
    { id: 11, name: 'Aadhar photo blur' },
    { id: 12, name: 'Photo invalid' },
    { id: 13, name: 'Name does not match' },
    { id: 14, name: 'DOB does not match' },
    { id: 15, name: 'Aadhar number is entered wrong' },
    { id: 16, name: 'Address wrong' },
    { id: 17, name: 'Aadhar verification failed' },
];
const KycDocument = (props) => {

    const dealer_id_hash = props.dealer_id_hash;
    const dealer_id = props.dealer_id;
    const kyc_master_id = props.kyc_master_id;
    const basic_detail = props.basic_details;
    const kycDealerData = props.kycDealerData;
    const bankList = props.bankList;
    const loginUserId=props.loginUserId;
    const [formData, setFormData] = useState({
        doc_number: '',
        comment: '',
        verified_status_new: '1',
        file_url: '',
        kyc_doc_type: '',
        dealer_id: "",
        kyc_master_id: kyc_master_id,
        basic_detail: basic_detail,
        kycDealerData: kycDealerData,
        bankName: bankList,
        reject_reason: 0,
        reject_reason_arr: []

    });
    const [dealerDocData, setDealerDocData] = useState({
        kycDealerDocData: []
    });
    const [Finaldata, setFinalData] = useState([])
    const [loading, setLoading] = useState(false);
    const [aadharDisabled, setAadharDisabled] = useState(false)
    const [aadharVerified, setAadharVerified] = useState(false);
    const [panDisabled, setPanDisabled] = useState(false);
    const [panGSTDisabled, setPanGSTDisabled] = useState(false)
    const [panVerified, setPanVerified] = useState(false);
    const [bankDisabled, setBankDisabled] = useState(false)
    const [bankVerified, setBankVerified] = useState(false);
    const [gstVerified, setGstVerified] = useState(false);
    const [companypPanVerified, setcompanypPanVerified] = useState(false);
    const [reject, setReject] = useState(false);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [imageExtention, setImageExtention] = useState(null);
    const [iconDisabled, setIconDisabled] = useState(false);

    const validationData = [
        {
            "master_doc_id": 1,
            "regex": "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
            "length": 12
        },
        {
            "master_doc_id": 2,
            "regex": "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
            "length": 12
        },
        {
            "master_doc_id": 3,
            "regex": "[A-Z]{5}[0-9]{4}[A-Z]{1}",
            "length": 10
        },
        {
            "master_doc_id": 4,
            "regex": "",
            "length": 1
        },
        {
            "master_doc_id": 5,
            "regex": "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
            "length": 15
        },
        {
            "master_doc_id": 6,
            "regex": "[A-Z]{5}[0-9]{4}[A-Z]{1}",
            "length": 10
        },
        {
            "master_doc_id": 7,
            "regex": "",
            "length": 5
        },
    ]

    const handleSaveClick = (basic_detail) => {
        // event.preventDefault();
        const approve = window.confirm("Are you sure want to change the Address?");
        if (approve === true) {
            setIconDisabled(true);
            saveBasicDetail(basic_detail);
        }
    }


    const saveBasicDetail = async (basic_detail) => {
        if (basic_detail) {
            const saveDetails = basic_detail;
            const requestData = {
                name: saveDetails?.first_name,
                email: saveDetails?.dealership_email,
                mobile: saveDetails?.dealership_contact,
                is_pan_verified: 1,
                //birth_date: saveDetails?.dealership_dob,
                applicant_type_id: saveDetails?.applicant_type ? Number(saveDetails?.applicant_type) : 1,
                profession_id: saveDetails?.profession_id,
                address: saveDetails?.address,
                pincode: saveDetails?.pincode ? String(saveDetails?.pincode) : "",
                user_type: "1",
                term_condition: true,
                partner_id: saveDetails?.id > 0 ? saveDetails.id : 0,
                user_id: saveDetails?.user_id > 0 ? saveDetails.user_id : 0,
                source: "Partner Backend",
                created_by: 0,
                pan_no: saveDetails?.pan_no,
                pan_number: saveDetails?.pan_no,
                sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails?.sfa_referral_user_id  : 0,
                //sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails.sfa_referral_user_id : saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
                //assigned_bm_id: saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
            }
            if (saveDetails?.latitude) {
                requestData.latitude = saveDetails?.latitude ? String(saveDetails?.latitude) : "";
            }
            if (saveDetails?.longitude) {
                requestData.longitude = saveDetails?.longitude ? String(saveDetails?.longitude) : "";
            }
            if (saveDetails?.dealership_dob) {
                let date_of_joining = new Date(saveDetails?.dealership_dob);
                let join_date = date_of_joining.getFullYear() + '-' + (date_of_joining.getMonth() + 1) + '-' + date_of_joining.getDate();
                requestData.birth_date = join_date;
            }

            if (saveDetails.aadhar_no) {
                requestData.aadhar_no = saveDetails?.aadhar_no;
            }
            if (saveDetails.applicant_type === "2") {
                requestData.company_type = saveDetails?.company_type;
            }

            if (saveDetails.is_aadhar_pan_linked === 1) {
                requestData.is_aadhar_pan_linked = 1;
                requestData.is_pan_verified = 1;
            }

            if (saveDetails?.applicant_type === "2" || saveDetails?.applicant_type === "3") {
                requestData.is_gst = saveDetails?.is_gst;
                requestData.gst_number = saveDetails?.gst_number;
                requestData.is_msme = saveDetails?.is_msme;
                requestData.msme_number = saveDetails?.msme_number;
                requestData.spoc_name = saveDetails?.spoc_name || "";
                requestData.spoc_email = saveDetails?.spoc_email || "";
                requestData.spoc_mobile = saveDetails?.spoc_phone_number || "";
                requestData.spoc_designation = saveDetails?.spoc_designation || "";
            }
            if(loginUserId){
                requestData.updated_by = loginUserId;
            }
            setLoading(true);
            await MasterService.post('/partner/partner/save-basic-details', requestData)
                .then(response => {
                  //  console.log("response.data", response.data);
                    if (response.data.status == 200 && response.status == 200) {
                        let responseData = response.data;
                        let other = {};
                        toast.success("Done");
                        let city_name = responseData.data && responseData.data.response && responseData.data.response.city_name;
                        let state_name = responseData.data && responseData.data.response && responseData.data.response.state_name;
                         
                        if(city_name){
                        basic_detail.cityname = city_name;
                        }
                        if(city_name){
                        basic_detail.state_name = state_name;
                        }

                        setLoading(false);
                    } else {
                        toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const RejectedPartnerPopup = async () => {
        try {
            if (formData) {
                const requestData = {
                    //user_id: formData?.user_id ||,
                    kyc_master_id: formData.kyc_master_id,
                }
                setLoading(true);
                await MasterService.post('/partner/partner/get-reject-reasons', requestData)
                    .then(response => {
                        setLoading(false);
                        if (response && response.data.status == 200 && response.status == 200) {
                            setReject(false);
                            setFormData({ ...formData, reject_reason_arr: response.data.data.response });

                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
        setReject(true);
    }
    const RejectedPartner = async () => {
        try {
            if (formData) {
                const requestData = {
                    partner_id: basic_detail?.id,
                    //user_id: formData?.user_id ||,
                    kyc_master_id: formData.kyc_master_id,
                    reject_reason: formData?.reject_reason ? formData?.reject_reason : 0,
                    reject_reason_comment: formData?.reject_reason_comment ? formData?.reject_reason_comment : "",
                }
                if(loginUserId){
                    requestData.updated_by = loginUserId;
                }
                await MasterService.post('/partner/partner/save-kyc-rejection', requestData)
                    .then(response => {
                        setLoading(false);
                        if (response && response.data.status == 200 && response.status == 200) {
                            setReject(false);
                            toast.success((response.data.message) ? response.data.message : "Success");
                            setFormData({ ...formData });
                        } else {
                            if (response.data.message.length > 0 && response.data.statusText != "OK") {
                                toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                            } else {
                                toast.error((response.data.message) ? response.data.message : "Error");
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }


    const handleRejectReasonChange = (ovalue) => {
        let reason = ovalue.id;
        setFormData({ ...formData, reject_reason: reason });
    }

    const handletChange = (e, key = "") => {
        const name = key || (e.target && e.target.name);
        const val = key || (e.target && e.target.value);
        if (name === "address") {
            basic_detail.address = val;
        }
        if (name === "pincode") {
            basic_detail.pincode = val;
        }
        setFormData({ ...formData, [name]: val });
    };

    const handleChangeVerifiedDocs = (sname, index) => {
        let is_active = formData.verified_status_new === '1' ? '0' : '1';
        let kycMasterlist_new = [...dealerDocData.kycDealerDocData];
        if (!kycMasterlist_new) {
            kycMasterlist_new = {};
        }
        kycMasterlist_new[sname] = is_active;
        setDealerDocData({ ...dealerDocData, kycDealerDocData: kycMasterlist_new });
        setFormData({ ...formData, verified_status_new: is_active });
    };
    const removecurrentdoc = () => {
        setFormData({
            ...formData, file_url: '', kyc_doc_type: '', verified_status_new: '0',
        });
    };

    const onChangeHandler = event => {
        let files = event.target.files;
        let err = [];
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf', 'application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        let previews = [];

        for (var x = 0; x < files.length; x++) {
            if (types.every(type => files[x].type !== type)) {
                err[x] = files[x].type + ' ' + ('is not a supported format') + ' \n';
                const fileType = files.type;
            }
            if (files[x].size && files[x].size > 8000000) {
                err[x] = ('File size should be less than 8 MB') + ' \n';
            }

            // Read file as data URL
            let reader = new FileReader();
            reader.onload = () => {
                previews.push(reader.result);
                if (previews.length === files.length) {
                    // Set image previews in state after all files are read
                    setImagePreviews(previews);
                    const imageExtension = previews ? previews[0].split(';')[0].split('/')[1] : ""; // Extract the image extension
                    setImageExtention(imageExtension);
                }
            };
            reader.readAsDataURL(files[x]);
        }

        for (var z = 0; z < err.length; z++) {
            event.target.value = null;
            toast.error(err[z]);
        }
    };

    const formDataToJson = (formData) => {
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        return data;
    };

    const validateForm = (event) => {
        const form = event.target;
        const data = new FormData(form);
        const formdata = formDataToJson(data);
        const validationDataObj = validationData.filter((formdata) => formdata.master_doc_id == kyc_master_id)
        const regex = validationDataObj.regex;
        const length = validationDataObj.length;
        return true;
    };

    useEffect(() => {
        getDealerDocKycDetail()
    }, [],)


    const getDealerDocKycDetail = async () => {
        DealerService.getDocKycDetails(dealer_id_hash).then(result => {
            if (result?.data?.status === 200 && result?.data?.data) {
                const doc_data = result?.data?.data
                setFinalData(doc_data);
                doc_data && doc_data.map((item) => {
                    if (item.kyc_master_id == kyc_master_id) {
                        setFormData(item)
                    }
                })
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    const validateAadharNumber = async (basic_detail, kyc_id) => {
        setLoading(true);
        try {
            if (basic_detail) {
                const saveDetails = basic_detail;
                const requestData = {
                    partner_id: saveDetails?.id,
                    user_id: saveDetails?.user_id,
                    kyc_master_id: kyc_id,
                    aadhar_number: saveDetails?.aadhar_no ? String(saveDetails?.aadhar_no) : "",
                }
                await MasterService.post('/partner/partner/validate-aadhar-number', requestData)
                    .then(response => {
                        setLoading(false);
                        if (response.data.statusCode == 200) {
                            toast.success((response.data.message) ? response.data.message : "Success")
                            setAadharVerified(true);
                            setAadharDisabled(true)
                        } else {
                            if (response.data.message.length > 0 && response.data.statusText != "OK") {
                                toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                            } else {
                                toast.error((response.data.message) ? response.data.message : "Error");
                                setAadharVerified(true);
                                setAadharDisabled(true)
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }

    const validatePanNumber = async (basic_detail, kyc_id) => {
        setLoading(true);
        try {
            if (basic_detail) {
                const saveDetails = basic_detail;
                const requestData = {
                    partner_id: saveDetails?.id,
                    user_id: saveDetails?.user_id,
                    kyc_master_id: kyc_id,
                    panNumber: saveDetails?.pan_no ? String(saveDetails?.pan_no) : "",
                }
                await MasterService.post('/partner/partner/validate-pan-number', requestData)
                    .then(response => {
                    //    console.log("response.data", response.data);
                        setLoading(false);
                        if (response.data.statusCode == 200 && response.data.statusCode == 200) {
                            let other = {};
                            toast.success((response.data.message) ? response.data.message : "Success")
                            setPanVerified(true)
                            setPanDisabled(true)
                            setcompanypPanVerified(true);
                            basic_detail.aadhar_no = response.data.data.maskedAadhaar || this.state.basic_details.aadhar_no;
                            basic_detail.is_pan_verified = 1;
                            basic_detail.is_aadhar_pan_linked = 1;
                            setFormData({ ...formData, basic_detail: basic_detail });
                        } else {
                            if (response.data.message.length > 0 && response.data.statusText != "OK") {
                                toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                            } else {
                                toast.error((response.data.message) ? response.data.message : "Error");
                                setPanVerified(true)
                                setPanDisabled(true)
                                setcompanypPanVerified(true)
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }

    const validatePanGSTNumber = async (basic_detail, kyc_id) => {
        setLoading(true);
        try {
            if (basic_detail) {
                const saveDetails = basic_detail;
                const requestData = {
                    partner_id: saveDetails?.id,
                    user_id: saveDetails?.user_id,
                    kyc_master_id: kyc_id,
                    panNumber: saveDetails?.pan_no ? String(saveDetails?.pan_no) : "",
                }
                await MasterService.post('/partner/partner/validate-pan-gst-number', requestData)
                    .then(response => {
                   //     console.log("response.data", response.data);
                        setLoading(false);
                        if (response.data.statusCode == 200 && response.data.statusCode == 200) {
                            let other = {};
                            toast.success((response.data.message) ? response.data.message : "Success")
                            setGstVerified(true)
                            setPanGSTDisabled(true)
                        } else {
                            if (response.data.message.length > 0 && response.data.statusText != "OK") {
                                toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                            } else {
                                setGstVerified(true)
                                setPanGSTDisabled(true)
                                toast.error((response.data.message) ? response.data.message : "Error");
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }

    const validateBankAccount = async (basic_detail, kyc_id, kycDealerData) => {
        setLoading(true);
        try {
            if (basic_detail) {
                const saveDetails = basic_detail;
                const requestData = {
                    partner_id: saveDetails?.id,
                    user_id: saveDetails?.user_id,
                    kyc_master_id: kyc_id,
                    beneficiary_name: kycDealerData.beneficiary_name ? kycDealerData.beneficiary_name : "",
                    account_no: kycDealerData?.bank_account_no ? String(kycDealerData?.bank_account_no) : "",
                    ifsc_code: kycDealerData?.bank_ifsc_code ? String(kycDealerData?.bank_ifsc_code) : "",
                }
            //    console.log("requestData", requestData)
                await MasterService.post('/partner/partner/validate-pennyless', requestData)
                    .then(response => {
                     //   console.log("response.data", response.data);
                        setLoading(false);
                        if (response.data.statusCode == 200 && response.data.statusCode == 200) {
                            let other = {};
                            toast.success((response.data.message) ? response.data.message : "Success")
                            setBankVerified(true)
                            setBankDisabled(true)
                            kycDealerData.is_name_matched = 1;
                            setFormData({ ...formData, kycDealerData: kycDealerData });
                        } else {
                            if (response.data.message.length > 0 && response.data.statusText != "OK") {
                                toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                            } else {
                                toast.error((response.data.message) ? response.data.message : "Error");
                                setBankVerified(true)
                                setBankDisabled(true)
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }

    const updateKycStatus = async (partner_id) => {
        try {
            if (partner_id) {
                const requestData = {
                    partner_id: partner_id,
                }
                if(loginUserId){
                    requestData.updated_by = loginUserId;
                }
                //console.log("requestData", requestData)
                await MasterService.post('/partner/partner/kyc-update-status', requestData)
                    .then(response => {
                     //   console.log("response.data", response.data);
                        if (response.data.statusCode == 200 && response.data.statusCode == 200) {
                            let other = {};

                        } else {

                        }
                    })
                    .catch(error => console.log(error))
            }
        } catch (err) {
            toast.error(err);
        }
    }


    const saveKycDetail = (event) => {
        event.preventDefault();
        setLoading(true);
        var formIsValid = false;
        var kyc_errors = [];
        var error = {};
        var parentThis = this;
        let key_error_msg = ""
        const form = event.target;
        const data = new FormData(form);
        const formdata = formDataToJson(data);
        setLoading(false);
        let allfiles = document.querySelectorAll('input[type="file"]');
        if (allfiles.length > 0 && allfiles[0].files.length == 0 && formdata.saved_file_name == "" && kyc_master_id !==4) {
            key_error_msg = key_error_msg + "Please upload doc file! "
            kyc_errors.push({ "doc_error": "Please upload doc file!" })
        } else {

        }
        if (formdata.doc_number == "") {
            key_error_msg = key_error_msg + "Document number is blank! "
            kyc_errors.push({ "doc_error": "Please add doc number" })
        }
        switch (kyc_master_id) {
            case 1:
                if (!basic_detail.first_name) {
                    toast.error("Invalid Name!");
                    return;
                }
                if (!basic_detail.dealership_dob) {
                    toast.error("Invalid DOB!");
                    return;
                }
                if (!basic_detail.aadhar_no) {
                    toast.error("Invalid Aadhar Number");
                    return;
                }

                break;
            case 3:
                if (!basic_detail.first_name) {
                    toast.error("Invalid Name!");
                    return;
                }
                if (!basic_detail.pan_no) {
                    toast.error("Invalid PAN Number");
                    return;
                }
                break;
            case 4:
                if (!kycDealerData || !kycDealerData.bank_account_no) {
                    toast.error("Invalid Account Number!");
                    return;
                }
                if (kycDealerData && !kycDealerData.bank_ifsc_code) {
                    toast.error("Invalid IFSC Code!");
                    return;
                }
                if (allfiles.length > 0 && allfiles[0].files.length == 0 && formdata.saved_file_name == "" && kycDealerData.is_name_matched !==1) {
                    toast.error("Please upload doc file!");
                    return;
                }
                if (kycDealerData && !kycDealerData.bank_id) {
                    toast.error("Invalid Bank!");
                    return;
                }
                break;
            case 5:
                if (!basic_detail.gst_number) {
                    toast.error("Invalid GST Number!");
                    return;
                }
                if (!basic_detail.first_name) {
                    toast.error("Invalid Name!");
                    return;
                }
                break;
            case 6:
                if (!basic_detail.organization) {
                    toast.error("Invalid Name!");
                    return;
                }
                if (!basic_detail.pan_no) {
                    toast.error("Invalid PAN Number");
                    return;
                }
                // case 5:
                //     if (!gstVerified) {
                //         toast.error("Please validate GST first.");
                //         return;
                //     }
                //     break;
                // case 6:
                //     if (!companypPanVerified) {
                //         toast.error("Please validate Company PAN No first.");
                //         return;
                //     }
                break;
            default:
        }

        const validationDataObj = validationData.filter((formdata) => formdata.master_doc_id == kyc_master_id)
        const validRules = validationDataObj && validationDataObj.length > 0 ? validationDataObj[0] : ""
        //  if(validRules && validRules.regex!="" && formdata.doc_number!=""){
        //     if (!formdata.doc_number.match(validRules.regex)) {
        //         key_error_msg = key_error_msg + "Document value is wrong! "
        //         kyc_errors.push({"doc_error": "Document value is wrong!"})
        //     }
        //  }
        // formdata && formdata.length > 0 && Object.keys(formdata).forEach((key) => {
        //     if (key == 'kyccategory' || key == 'kycdoc' || key == 'docnumber' || key == "verified_status_new") {
        //         let key_error_msg = '';
        //         if (key == 'kyccategory') {
        //             key_error_msg = 'key category';
        //         } else if (key == 'kycdoc') {
        //             key_error_msg = 'key doc';
        //         } else if (key == 'docnumber') {
        //             key_error_msg = 'key doc number';
        //         }
        //         if (Array.isArray(formdata[key]) && formdata[key].length > 0) {
        //             formdata[key].forEach((fileskyc, index) => {
        //                 let value = formdata[key];
        //                 let key_doc_value = formdata['kycdoc'];
        //                 if (key == 'docnumber') {
        //                     let tempError = {};
        //                     kyc_errors = tempError;
        //                     if (value != '' && key_doc_value == 2) {
        //                         if (!value.match(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\.[0-9]\-[0-9]{3}\.[0-9]{3}$/)) {
        //                             error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
        //                             tempError['docnumber'] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
        //                             kyc_errors = tempError;
        //                         }
        //                     }
        //                 } else if (!value) {
        //                     error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
        //                     kyc_errors = error;
        //                 }
        //             })
        //         } else {
        //             let value = formdata[key];
        //             if (!value) {
        //                 error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
        //                 kyc_errors[0] = error;
        //             }
        //         }

        //     }

        // }) 
        setFormData({ ...formData, kyc_errors });

        setFormData({ ...formData, ["error_msg"]: key_error_msg })
        if ((kyc_errors.length) === 0) {
            formIsValid = true;
        }

        if (formIsValid) {
            let allfiles = document.querySelectorAll('input[type="file"]');
            s3UploadFiles(allfiles, formData);
            
        } else {
            toast.error(key_error_msg);
        }

    }

    const s3UploadFiles = (allfiles, formPostdata) => {
        let dealerKycDocSaveArr = [...Finaldata];
        if (allfiles.length) {
            setFormData({ ...formData, loading: false });
            let prom = [];
            allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();

                formData.append("upload_type", 'dealer_docs');
                formData.append("dealer_id", dealer_id);
 
                if (filesData.length) {
                    formData.append('images', filesData[0]);
                    prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            });

            Q.allSettled(prom)
                .then((op) => {
                    op.forEach((res, index) => {
                        let response = res.value || [];
                        if (res.state == 'fulfilled') {
                            // return true;
                            // console.log("dffff", formPostdata);
                            if (response.data.data.length) {
                                let tempFileName = '';
                                let tempFileUrl = '';
                                let temp_kyc_master_id = 0;
                                let dealerKycDocSaveJson = {};

                                if (formPostdata.kyccategory && formPostdata.kyccategory.length > 1) {
                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyccategory,
                                        "kyc_child_id": formPostdata.kycdoc,
                                        "doc_number": (formPostdata.docnumber) ? (formPostdata.docnumber) : '',
                                        "comment": formPostdata.Comment ? formPostdata.Comment : '',
                                        "verified_status": "1",
                                        //"verified_status": formPostdata.verified_status_new && (formPostdata.verified_status_new == 'on' || formPostdata.verified_status_new == '1') ? '1' : '0'
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc != null && formPostdata.dc_dealer_kyc_doc > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;

                                    }
                                    if (formPostdata?.file_url != null && formPostdata?.file_url && !formPostdata?.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.file_url;
                                    }

                                    if (formPostdata.id != null && formPostdata.id > 0 && !formPostdata.dc_dealer_kyc_doc) {
                                        let dc_dealer_kyc_doc = formPostdata.id;
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                } else {

                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyc_master_id,
                                        "kyc_child_id": formPostdata.kycdoc,
                                        "doc_number": (formPostdata.doc_number) ? formPostdata.doc_number : '',
                                        "comment": (formPostdata.comment) ? formPostdata.comment : '',
                                        "verified_status": "1",
                                        //"verified_status": formPostdata.verified_status_new && (formPostdata.verified_status_new == 'on' || formPostdata.verified_status_new) == '1' ? '1' : '0'
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc != null && formPostdata.dc_dealer_kyc_doc > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                    }
                                    if (formPostdata?.file_url != null && formPostdata?.file_url && !formPostdata?.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.file_url;

                                    }
                                    if (formPostdata.id != null && formPostdata.id > 0 && !formPostdata.dc_dealer_kyc_doc) {
                                        let dc_dealer_kyc_doc = formPostdata.id;
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                }
                                if (response.data.data[0] != 'not_updated') {
                                    tempFileName = response.data.data[0].file_name;
                                    tempFileUrl = response.data.data[0].file_url;
                                    dealerKycDocSaveJson["file_name"] = tempFileName;
                                    dealerKycDocSaveJson["file_url"] = tempFileUrl;
                                }

                                dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                                setFinalData(dealerKycDocSaveArr)
                            }
                        }
                    });

                    let postData = {
                        dealer_id_hash: dealer_id_hash,
                        docs: dealerKycDocSaveArr
                    };
                    DealerService.saveKycDocDetails(postData).then(result => {
                        if (result && result.data && result.data.status === 200) {
                            setLoading(false);
                            updateKycStatus(dealer_id);
                            toast.success(result.data.message, {
                                //onClose: () => window.location.reload(true)
                            });
                            setTimeout(() => {
                                window.location.reload(true);
                            }, 1000);

                        }
                        else {
                            setFormData({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        setFormData({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });
                })
                .catch((error) => {
                    setFormData({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
        }
    }

    const formatDate = (dateString) => {
        // Create a new Date object from the input string
        const date = new Date(dateString);

        // Extract the components of the date (month, day, and year)
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        // Return the formatted date string (MM/DD/YYYY)
        return `${month}/${day}/${year}`;
    };
    return (
        <>

            <div>
                {/* {JSON.stringify(formData)} */}
                <form className={(loading) ? 'text-left os-animation animated fadeInDown loading' : 'text-left os-animation animated fadeInDown'} data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="basicdetail_form" onSubmit={saveKycDetail} encType="multipart/form-data">
                    <div className='row'>
                        <div className='kyc-heading'>
                            <h6 className="col-sm-6">{('Images Details')}</h6>
                            <h6 className="col-sm-6">{('Document Details')}</h6>
                        </div>
                    </div>
                    <div >
                        <div style={{ display: "flex", gap: "20px" }}>
                            <input type="hidden" name="dc_dealer_kyc_doc" id="dc_dealer_kyc_doc" value={(formData.id) ? formData.id : ''} ></input>
                            <input type="hidden" name="saved_file_name" id="saved_file_name" value={(formData.file_name) ? formData.file_name : ''}></input>
                            <input type="hidden" name="saved_file_url" id="saved_file_url" value={(formData.file_url) ? formData.file_url : ''}></input>
                            <input type="hidden" name="verified_status" id="verified_status" value='1'></input>
                            {formData.verified_status === '001' ? (
                                <div>
                                    {/* as per new design <div>{
                                        (formData.file_url != '' && formData.file_url != null) ?
                                            <div className="img-document">
                                                {
                                                    (formData.file_url != '' && formData.file_url != null) ?
                                                        <a href={formData.file_url} target="_blank" download>
                                                            {
                                                                (formData.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (formData.kyc_doc_type == 'image') ? <img src={formData.file_url} width={450} height={300}></img> : (formData.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                            }

                                                        </a>
                                                        : ''
                                                }
                                                {formData.verified_status != '1' ? <span className="delete-docs" onClick={() => removecurrentdoc()}><i className="ic-clearclose"></i></span> : ""}
                                            </div>
                                            : ''
                                    }</div> */}
                                    {/* <div className="col-sm-6">
                                        <div style={{ marginBottom: 10 }}>
                                            <label>{(formData.kyc_master_id == 1 || formData.kyc_master_id == 2) ? "Aadhar Number" : (formData.kyc_master_id == 3) ? "PAN Number" : (formData.kyc_master_id == 4) ? "Document Number" : (formData.kyc_master_id == 5) ? "GSTN" : (formData.kyc_master_id == 6) ? "Company PAN" : (formData.kyc_master_id == 7) ? "MSME Certificate" : "Document Number"} </label>
                                            <div style={{ fontWeight: 700 }}>
                                                {formData.doc_number}
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: 10 }}>
                                            <label>Comments </label>
                                            <div style={{ fontWeight: 700, wordWrap: 'break-word' }}>
                                                {formData.comment}
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: 10 }}>{formData.verified_status === '1' ? <img src={require('./../../../webroot/images/tick.png')} alt="verified" width={30} height={30} /> : "Pending"} {formData.verified_date ? DateFormate(formData.verified_date, 'dS mmm yyyy h:MM TT') : ""} </div>
                                    </div> */}

                                </div>) : (
                                <>
                                    <div style={{ width: "50%" }}>
                                        <label>
                                            {formData.kyc_master_id === 4 && (
                                                "Cancelled Cheque Details"
                                            )}
                                            {formData.kyc_master_id !== 4 && (
                                                "Image"
                                            )}

                                        </label>

                                        <div className={
                                            (formData.file_url != '' && formData.file_url != null) ?
                                                ' img-bx-select-opt form-control hide' : ' img-bx-select-opt '}>
                                            {/* {formData.file_url === '' || formData.file_url === null ? ( */}
                                            <div className="image-bx more-file-upload">
                                                <img src={require(`./../../dealer/assets/images/upload_icn.svg`)} alt="" width={30} height={30} />
                                                <input type="file" name="fileName" multiple className={
                                                    (formData.file_url != '' && formData.file_url != null) ?
                                                        'form-control hide' : 'form-control'
                                                } onChange={(e) => onChangeHandler(e)} />
                                                <p>Upload Images</p>
                                            </div>
                                            {/* ) : null} */}
                                        </div>



                                        {/* <input type="file" name="fileName" multiple className={
                                            (formData.file_url != '' && formData.file_url != null) ?
                                                'form-control hide' : 'form-control'
                                        } onChange={(e) => onChangeHandler(e)} /> */}
                                        {
                                            (formData.file_url != '' && formData.file_url != null) ?
                                                <>
                                                    <div className="kyc-document" style={{ display: "flex" }}>
                                                        <div style={{ width: 500, height: 350, position: "relative", overflow: "hidden" }}>
                                                            {/* <a href={formData.file_url} target="_blank" download> */}
                                                            <div className="image-preview-container">
                                                                <div className="pfd-img-height">
                                                                    {
                                                                        (formData.file_url != '' && formData.file_url != null) ?

                                                                            (formData.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (formData.kyc_doc_type == 'image') ? <ReactPanZoom image={formData.file_url} src={formData.file_url} width={600} height={300} /> : (formData.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* as per new design {formData.verified_status != '1' ? <span className="delete-docs" onClick={() => removecurrentdoc()}><i className="ic-clearclose"></i></span> : ""} */}
                                                            {/* </a> */}
                                                        </div>
                                                        <a style={{ marginLeft: 20 }} href={formData.file_url} target="_blank" download><span > <img style={{ width: 25 }} src={require(`./../../dealer/assets/images/download.svg`)} alt="" /></span> </a>
                                                    </div>
                                                </>
                                                : ''
                                        }
                                        {imageExtention !== null ?
                                            <div
                                                style={{
                                                    width: 500,
                                                    height: 350,
                                                    position: "relative",
                                                    overflow: "hidden"
                                                }}
                                            >

                                                {imagePreviews && imagePreviews.length > 0 && (
                                                    <div className="image-preview-container">
                                                        <div className="pfd-img-height">
                                                            {imagePreviews.map((preview, index) => (
                                                                imageExtention !== "pdf" && (
                                                                    <ReactPanZoom key={index} image={preview} alt={`Image Preview ${index}`} className="image-preview" />
                                                                )
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}


                                            </div>
                                            : ''
                                        }

                                        <div>
                                            {formData.kyc_master_id === 1 && basic_detail.is_pan_verified === 1 && basic_detail.is_aadhar_pan_linked === 1 && (
                                                <div className='verifiedkyc'>
                                                    <div style={{ marginRight: 20 }}>
                                                        <img src={require(`./../../dealer/assets/images/greentick.svg`)} alt="" />
                                                    </div>
                                                    <div>Aadhaar and PAN are Linked</div>
                                                </div>
                                            )}

                                            {formData.kyc_master_id === 1 && basic_detail.is_pan_verified === 1 && basic_detail.is_aadhar_pan_linked !== 1 && (
                                                <div className='notverifiedkyc'>
                                                    <div style={{ marginRight: 20 }}>
                                                        <img src={require(`./../../dealer/assets/images/notverified.svg`)} alt="" width={20} height={20} />
                                                    </div>
                                                    <div>Aadhaar and PAN are not Linked</div>
                                                </div>
                                            )}
                                            {formData.kyc_master_id === 3 && basic_detail.is_pan_verified === 1 && (
                                                <div className='verifiedkyc'>
                                                    <div style={{ marginRight: 20 }}>
                                                        <img src={require(`./../../dealer/assets/images/greentick.svg`)} alt="" width={20} height={20} />
                                                    </div>
                                                    <div>PAN No. is valid - Please cross-check the document and verify</div>
                                                </div>
                                            )}

                                            {formData.kyc_master_id === 4 && kycDealerData && kycDealerData.is_name_matched === 1 && (
                                                <div className='verifiedkyc'>
                                                    <div style={{ marginRight: 20 }}>
                                                        <img src={require(`./../../dealer/assets/images/greentick.svg`)} alt="" />
                                                    </div>
                                                    <div>Beneficiary name matched to PAN</div>
                                                </div>
                                            )}

                                            {formData.kyc_master_id === 4 && kycDealerData && kycDealerData.is_name_matched === 2 && (
                                                <div className='notverifiedkyc'>
                                                    <div style={{ marginRight: 20 }}>
                                                        <img src={require(`./../../dealer/assets/images/notverified.svg`)} alt="" width={20} height={20} />
                                                    </div>
                                                    <div>Beneficiary name not matching to PAN</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {formData.kyc_master_id == 1 || formData.kyc_master_id == 2 ?
                                        <div style={{ width: "50%" }}>
                                            <div className="row" >
                                                <div className="col-sm-6">
                                                    <InputField
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        label='Name'
                                                        placeholder='name'
                                                        value={basic_detail.first_name}
                                                        onChange={(e) => handletChange(e)}
                                                        disabled={true}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        type="text"
                                                        name="dob"
                                                        id="dob"
                                                        label='DOB'
                                                        placeholder='DOB'
                                                        value={basic_detail.dealership_dob ? formatDate(basic_detail.dealership_dob) : ""}
                                                        onChange={(e) => handletChange(e)}
                                                        disabled={true}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField
                                                        type="text"
                                                        name="aadhar_numbar"
                                                        id="aadhar_numbar"
                                                        label='Aadhar Numbar'
                                                        placeholder='Aadhar Numbar'
                                                        value={basic_detail.aadhar_no}
                                                        onChange={(e) => handletChange(e)}
                                                        disabled={true}
                                                        required={true}
                                                    />
                                                </div>
                                                {formData.kyc_master_id === 1 && basic_detail?.is_aadhar_pan_linked !== 1 ?
                                                    <div className="col-sm-6" style={{ marginTop: 25 }}>
                                                        <div disabled={aadharDisabled} className="kycverification" onClick={() => validatePanNumber(basic_detail, 1)} >{(basic_detail?.is_aadhar_pan_linked === 1) ? 'Approved' : 'Validate Aadhaar-PAN Linkage'}</div>
                                                    </div>
                                                    : ""}

                                            </div>
                                            <div className='kycaddress'>
                                                <h6>Address Details</h6>
                                                <div className="row" >
                                                    <div className="col-sm-11">
                                                        <InputField
                                                            type="text"
                                                            name="address"
                                                            id="address"
                                                            label="Address"
                                                            placeholder='address'
                                                            value={basic_detail.address}
                                                            onChange={(e) => handletChange(e)}
                                                            disabled={formData.verified_status == '1' ? true : false}
                                                        />
                                                    </div>
                                                    {formData.verified_status !== '1' &&
                                                        <div style={{ marginTop: 28 }}>
                                                            <img
                                                                src={iconDisabled ? require(`./../../dealer/assets/images/saveicondisabled.svg`) : require(`./../../dealer/assets/images/saveicon.svg`)}
                                                                alt=""
                                                                width={30}
                                                                height={35}
                                                                onClick={() => handleSaveClick(basic_detail)}
                                                                style={{ cursor: iconDisabled ? 'not-allowed' : 'pointer' }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row" >
                                                    <div className="col-sm-4">
                                                        <InputField
                                                            type="text"
                                                            name="pincode"
                                                            id="pincode"
                                                            label='Pin Code'
                                                            placeholder='pin code'
                                                            value={basic_detail.pincode}
                                                            onChange={(e) => handletChange(e)}
                                                            disabled={formData.verified_status == '1' ? true : false}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField
                                                            type="text"
                                                            name="partner_state"
                                                            id="partner_state"
                                                            label='State'
                                                            placeholder='State'
                                                            value={basic_detail.state_name}
                                                            onChange={(e) => handletChange(e)}
                                                            disabled={true}
                                                        />
                                                    </div>

                                                    <div className="col-sm-4">
                                                        <InputField
                                                            type="text"
                                                            name="city"
                                                            id="city"
                                                            label='City'
                                                            placeholder='City'
                                                            value={basic_detail.cityname}
                                                            onChange={(e) => handletChange(e)}
                                                            disabled={true}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-sm-12">
                                                    <InputField
                                                        type="text"
                                                        name="comment"
                                                        id="comment"
                                                        label="Comment"
                                                        placeholder='comment'
                                                        value={formData.comment}
                                                        onChange={(e) => handletChange(e)}
                                                    />
                                                </div>


                                            </div>
                                        </div> : ""}






                                    {/* <div className="col-sm-6">
                                   
                                        <InputField
                                            type="text"
                                            name="doc_number"
                                            id="docnumber"
                                            label={(formData.kyc_master_id==1 || formData.kyc_master_id==2) ? "Aadhar Number" : (formData.kyc_master_id==3) ? "PAN Number" : (formData.kyc_master_id==4) ? "Document Number" : (formData.kyc_master_id==5) ? "GSTN" : (formData.kyc_master_id==6)? "Company PAN" : (formData.kyc_master_id==7)? "MSME Certificate" :"Document Number"} 
                                            placeholder={props.t('dealerDetails.kyc_details.document_number')}
                                            value={formData.doc_number}
                                            onChange={(e) => handletChange(e)}
                                        />

                                        <InputField
                                            type="text"
                                            name="comment"
                                            id="comment"
                                            label="Comment"
                                            placeholder={props.t('dealerDetails.kyc_details.comment')}
                                            value={formData.comment}
                                            onChange={(e) => handletChange(e)}
                                        />

                                       
                                        <div style={{ margin: 8.5 }}>
                                            <input
                                                type="checkbox"
                                                name="verified_status_new"
                                                label ="Veify"
                                                id={'verified_status_new'}
                                                key={'verified_status_new'}
                                                onClick={() => handleChangeVerifiedDocs("verified_status_new", 0)}
                                                checked={formData.verified_status_new === '1'}
                                            /> 
                                            <label style={{marginLeft:10}}> Verify </label>

                                        </div>

                                       {formData.error_msg && formData.error_msg!="" ? 
                                        <div style={{ margin: 8.5 }}>
                                            <span className="error show">{formData.error_msg}</span>
                                         </div>
                                       
                                       : ""}

                                    </div> */}
                                </>)}



                            {formData.kyc_master_id == 3 ?
                                <div style={{ width: "50%" }}>
                                    <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="name"
                                                id="name"
                                                label='Name'
                                                placeholder='name'
                                                value={basic_detail.first_name}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="pan_number"
                                                id="pan_number"
                                                label='PAN Number'
                                                placeholder='PAN number'
                                                value={basic_detail.pan_no}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>

                                        {/* <div className="col-sm-4" style={{ marginBottom: 20 }}>
                                            <div disabled={panDisabled} className="kycverification" onClick={() => validatePanNumber(basic_detail, 3)}  >{(basic_detail?.pan_number === 1) ? 'Approved' : 'Validate PAN Details'}</div>
                                        </div> */}


                                        <div className="col-sm-12">
                                            <InputField
                                                type="text"
                                                name="comment"
                                                id="comment"
                                                label="Comment"
                                                placeholder='comment'
                                                value={formData.comment}
                                                onChange={(e) => handletChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div> : ""}
                            {formData.kyc_master_id == 4 ?
                                <div style={{ width: "50%" }}>
                                    <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="ac_number"
                                                id="ac_number"
                                                label='Account Number'
                                                placeholder='ac number'
                                                value={kycDealerData ? kycDealerData.bank_account_no : ""}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="ifsc_code"
                                                id="ifsc_code"
                                                label='IFSC Code'
                                                placeholder='ifsc code'
                                                value={kycDealerData ? kycDealerData.bank_ifsc_code : ""}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="bank_name"
                                                id="bank_name"
                                                label='Bank Name'
                                                placeholder='bank name'
                                                value={bankList && kycDealerData && bankList.length > 0 && kycDealerData.bank_id > 0 ? bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)[0].bankname : ""}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="beneficiary_name"
                                                id="beneficiary_name"
                                                label='Beneficiary Name'
                                                placeholder='Beneficiary Name'
                                                value={kycDealerData ? kycDealerData.beneficiary_name : ""}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="partner_state"
                                                id="partner_state"
                                                label='State'
                                                placeholder='State'
                                                value={basic_detail.state_name}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="city"
                                                id="city"
                                                label='City'
                                                placeholder='City'
                                                value={basic_detail.cityname}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="upi_id"
                                                id="upi_id"
                                                label='UPI Id'
                                                placeholder='UPI Id'
                                                value={kycDealerData ? kycDealerData.upi_id : ""}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                            />
                                        </div>
                                        {formData.kyc_master_id === 4 && kycDealerData?.is_name_matched === 2 ?
                                            <div className="col-sm-4" style={{ marginTop: 28 }}>
                                                {/* <div className="kycverification" onClick={''} >Veify Bank Account</div> */}
                                                <div disabled={bankDisabled} className="kycverification" onClick={() => validateBankAccount(basic_detail, 4, kycDealerData)}  >{(basic_detail?.bank_name === 1) ? 'Approved' : 'Verify A/c Details'}</div>
                                            </div>
                                            : ""}

                                        <div className="col-sm-12">
                                            <InputField
                                                type="text"
                                                name="comment"
                                                id="comment"
                                                label="Comment"
                                                placeholder='comment'
                                                value={formData.comment}
                                                onChange={(e) => handletChange(e)}
                                            />
                                        </div>


                                    </div>

                                </div> : ""}

                            {formData.kyc_master_id == 5 ?
                                <div style={{ width: "50%" }}>
                                    <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="gst_number"
                                                id="gst_number"
                                                label='GSTIN'
                                                placeholder='GSTIN'
                                                value={basic_detail.gst_number}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="name"
                                                id="name"
                                                label='Name'
                                                placeholder='Name'
                                                value={basic_detail.first_name}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="partner_state"
                                                id="partner_state"
                                                label='State'
                                                placeholder='State'
                                                value={basic_detail.state_name}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="col-sm-4" style={{ marginTop: 28 }}>
                                            {/* <div className="kycverification" onClick={''} >Veify Bank Account</div> */}
                                            {/* <div className="kycverification"  >validate GST</div> */}
                                            {/* <div disabled={bankDisabled} className="kycverification" onClick={() => validatePanGSTNumber(basic_detail, 5, kycDealerData)}  >{(basic_detail?.is_gst === 1) ? 'Approved' : 'validate GST'}</div> */}
                                        </div>


                                        <div className="col-sm-12">
                                            <InputField
                                                type="text"
                                                name="comment"
                                                id="comment"
                                                label="Comment"
                                                placeholder='comment'
                                                value={formData.comment}
                                                onChange={(e) => handletChange(e)}
                                            />
                                        </div>





                                    </div>
                                </div> : ""}


                            {formData.kyc_master_id == 6 ?
                                <div style={{ width: "50%" }}>
                                    <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="company_name"
                                                id="company_name"
                                                label='Company Name'
                                                placeholder='company name'
                                                value={basic_detail.organization}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="company_pan_number"
                                                id="company_pan_number"
                                                label='Company PAN Number'
                                                placeholder='Company PAN number'
                                                value={basic_detail.pan_no}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>

                                        {/* <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                            <div className="kycverification" onClick={() => validatePanNumber(basic_detail, 3)}  >Verify Company PAN Details</div>
                                        </div> */}


                                        <div className="col-sm-12">
                                            <InputField
                                                type="text"
                                                name="comment"
                                                id="comment"
                                                label="Comment"
                                                placeholder='comment'
                                                value={formData.comment}
                                                onChange={(e) => handletChange(e)}
                                            />
                                        </div>


                                    </div>
                                </div> : ""}

                            {formData.kyc_master_id == 7 ?
                                <div style={{ width: "50%" }}>
                                    <div className="row" >
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="msme_number"
                                                id="msme_number"
                                                label='MSME'
                                                placeholder='MSME'
                                                value={basic_detail.msme_number}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="name"
                                                id="name"
                                                label='Name'
                                                placeholder='Name'
                                                value={basic_detail.first_name}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <InputField
                                                type="text"
                                                name="partner_state"
                                                id="partner_state"
                                                label='State'
                                                placeholder='State'
                                                value={basic_detail.state_name}
                                                onChange={(e) => handletChange(e)}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="col-sm-12">
                                            <InputField
                                                type="text"
                                                name="comment"
                                                id="comment"
                                                label="Comment"
                                                placeholder='comment'
                                                value={formData.comment}
                                                onChange={(e) => handletChange(e)}
                                            />
                                        </div>


                                    </div>
                                </div> : ""}

                        </div>
                        {/* <div className='pull-right' style={{ marginBottom:40 }}>
                                    <div >
                                            <input
                                                type="checkbox"
                                                name="verified_status_new"
                                                label ="Veify"
                                                id={'verified_status_new'}
                                                key={'verified_status_new'}
                                                onClick={() => handleChangeVerifiedDocs("verified_status_new", 0)}
                                                checked={formData.verified_status_new === '1'}
                                            /> 
                                            <label style={{marginLeft:10}}> Verify </label>

                                        </div>

                                       {formData.error_msg && formData.error_msg!="" ? 
                                        <div style={{ margin: 8.5 }}>
                                            <span className="error show">{formData.error_msg}</span>
                                         </div>
                                       
                                       : ""}
                                    </div> */}
                    </div>
                    <div className="card-footer text-center" style={{ marginTop: 20 }}>
                        <div>
                            <div className="text-right">
                                {/* <button className="approvebtn " style={{ marginRight: 20,background: "red"  }} onClick={RejectedPartnerPopup}>Reject</button> */}
                                {
                                            (formData.file_url != '' && formData.file_url != null) ?
                                <div > <div className="approvebtn " style={{ marginLeft: "84%", height: 32, marginBottom: -32, background: "red", width: 75, display: "flex", justifyContent: "center" }} onClick={RejectedPartnerPopup} >Reject</div></div>
                                : ''
                            }
                                <Button btnClass="approvebtn" disabled={formData.verified_status == '1'} title={formData.verified_status == '1' ? 'Verified' : ('Verify')} type="submit" />
                            </div>
                        </div>
                    </div>



                </form >
                <div >
                    <Modal isOpen={reject} togglePopup={() => setReject(false)} width={800}>
                        <div>
                            <h4>Are you sure you want to reject this KYC?</h4>
                            <div>
                                <label>Select a reason *</label>
                                <Select
                                    id="kyc_reject"
                                    onChange={(e) => handleRejectReasonChange(e)}
                                    options={formData.reject_reason_arr}
                                    name="kyc_reject"
                                    placeholder='Select Rejection Reason'
                                    value={formData.reject_reason_arr && formData.reject_reason_arr.filter(({ id }) => id == formData.reject_reason)}
                                    getOptionLabel={({ reject_reason }) => reject_reason}
                                    getOptionValue={({ id }) => id}
                                />
                                <div className="">
                                    <label>Comments <span style={{ color: "red" }}>*</span></label>
                                    <textarea
                                        maxLength="2000"
                                        name="reject_reason_comment"
                                        placeholder="Comments"
                                        className="form-control"
                                        onChange={(e) => handletChange(e)}
                                        value={formData && formData.reject_reason_comment|| ""}>

                                    </textarea>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <button className="btn btn-primary pull-right " onClick={RejectedPartner} >Reject</button>
                                    {/* <button className="btn btn-primary mrg-r10 pull-right ">Reject</button> */}
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>

            </div >


        </>
    );
}


export default withTranslation('common')(KycDocument);
